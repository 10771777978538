import React, { useState, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
/* Context */
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import TabsComp from "../../../components/DelegatesPageComp/AddNewDelegateComp/TabsComp/TabsComp";
import CopyRights from "../../../components/DelegatesPageComp/AddNewDelegateComp/CopyRights/CopyRights";
import { LoadingContext } from "../../../store/LoadingProvider";
/* MUI */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
/* Custom Button */
import Button from "../../../UI/Button/Button";
import { useAddNewMarketerMutation } from "../../../RTK/apiSlices/marketersApi";

const DelegatesTablePage = () => {
	const navigate = useNavigate();

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	// to disable || enable submit button
	const [valid, setValid] = useState(false);

	// to upload image
	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			// personal info
			name: "",
			email: "",
			password: "",
			password_confirm: "",
			user_name: "",
			phonenumber: "",

			// social media links
			snapchat: "",
			facebook: "",
			twiter: "",
			whatsapp: "",
			youtube: "",
			instegram: "",
			city_id: "",
			country_id: "",
			status: "",
		},
	});

	// to set Agree Conditions
	const [condition, setCondition] = useState(1);
	const handleCondition = (e) => {
		setValid(!valid);
		setCondition(e.target.value);
	};

	const [DataError, setDataError] = useState({
		name: "",
		email: "",
		password: "",
		phonenumber: "",
		password_confirm: "",
		user_name: "",
		periodtype: "",
		city_id: "",
		country_id: "",
		image: "",
		snapchat: "",
		facebook: "",
		twiter: "",
		whatsapp: "",
		youtube: "",
		instegram: "",
		status: "",
		checkbox_field: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			email: "",
			password: "",
			phonenumber: "",
			password_confirm: "",
			user_name: "",
			periodtype: "",
			city_id: "",
			country_id: "",
			image: "",
			snapchat: "",
			facebook: "",
			twiter: "",
			whatsapp: "",
			youtube: "",
			instegram: "",
			status: "",
			checkbox_field: "",
		});
	};

	const [addNewMarketer] = useAddNewMarketerMutation();

	const addNewMarketerHandel = async (formData) => {
		try {
			const res = await addNewMarketer({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/marketers");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					password: res?.data?.message?.en?.password?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					password_confirm: res?.data?.message?.en?.password_confirm?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					periodtype: res?.data?.message?.en?.periodtype?.[0],
					city_id: res?.data?.message?.en?.city_id?.[0],
					country_id: res?.data?.message?.en?.country_id?.[0],
					image: res?.data?.message?.en?.image?.[0],
					snapchat: res?.data?.message?.en?.snapchat?.[0],
					facebook: res?.data?.message?.en?.facebook?.[0],
					twiter: res?.data?.message?.en?.twiter?.[0],
					whatsapp: res?.data?.message?.en?.whatsapp?.[0],
					youtube: res?.data?.message?.en?.youtube?.[0],
					instegram: res?.data?.message?.en?.instegram?.[0],
					status: res?.data?.message?.en?.status?.[0],
					checkbox_field: res?.data?.message?.en?.checkbox_field?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.password_confirm?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.periodtype?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.city_id?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.country_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.snapchat?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.facebook?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.twiter?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.whatsapp?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.youtube?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.instegram?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.status?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.checkbox_field?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	// define this functions to post all add market data to server
	const handleNewMarketer = (data) => {
		setLoadingTitle("جاري حفظ المندوب");
		resetData();
		let formData = new FormData();
		formData.append("name", data?.name);
		formData.append("email", data?.email);
		formData.append("password", data?.password);
		formData.append("password_confirm", data?.password_confirm);
		formData.append("user_name", data?.user_name);
		formData.append(
			"phonenumber",
			data?.phonenumber?.startsWith("+966") ||
				data?.phonenumber?.startsWith("00966")
				? data?.phonenumber
				: `+966${data?.phonenumber}`
		);

		/** ------------------------------------------- */
		formData.append("city_id", data?.city_id);
		formData.append("country_id", data?.country_id);

		/** ------------------------------------------- */
		formData.append("image", images[0]?.file || "");
		/** ------------------------------------------- */
		formData.append("snapchat", data?.snapchat);
		formData.append("facebook", data?.facebook);
		formData.append("twiter", data?.twiter);
		formData.append(
			"whatsapp",
			data?.whatsapp?.startsWith("+966") || data?.whatsapp?.startsWith("00966")
				? data?.whatsapp
				: `+966${data?.whatsapp}`
		);
		formData.append("youtube", data?.youtube);
		formData.append("instegram", data?.instegram);

		/** ------------------------------------------- */
		formData.append("status", data?.status);

		/** ------------------------------------------- */
		formData.append("checkbox_field", condition);

		addNewMarketerHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | اضافة مندوب</title>
			</Helmet>
			<div
				className={`md:px-5 md:py-10 p-4 pt-0 relative md:top-0 -top-[72px] `}
				style={{ backgroundColor: "#F7F7F7" }}>
				<div className='flex justify-between items-center md:text-lg text-[16px] font-medium py-4 md:py-1'>
					<PageNavigate
						parentPage={"عرض المندوبين"}
						route={"marketers"}
						currentPage={"اضافة مندوب جديد"}
					/>
				</div>
				<form onSubmit={handleSubmit(handleNewMarketer)} className='h-full'>
					<TabsComp
						props={register}
						control={control}
						errors={errors}
						onChange={onChange}
						images={images}
						DataError={DataError}
					/>
					<CopyRights />
					<FormGroup>
						<FormControlLabel
							sx={{
								mt: "40px",
								py: 1,
								mr: 0,
								"& .MuiTypography-root": {
									fontSize: "18px",
									fontWeight: "500",
									color: "#1DBBBE",
								},
							}}
							control={
								<Checkbox
									name='checkbox_field'
									value={condition}
									onChange={handleCondition}
									sx={{ "& path": { fill: "#1DBBBE" } }}
								/>
							}
							label='أوافق على الشروط أعلاه'
						/>
					</FormGroup>
					<span className='text-red-500 text-[16px]'>
						{DataError?.checkbox_field}
					</span>
					<Button
						btnType={"submit"}
						disabled={!valid}
						className={"mt-[26px] py-4"}
						type={"normal"}
						style={{ width: "100%" }}>
						تسجيل
					</Button>
				</form>
			</div>
		</>
	);
};

export default DelegatesTablePage;
