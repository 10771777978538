import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./SideBar.module.css";
/* Third party */
import { Link } from "react-router-dom";
/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* MUI */
import TreeView from "@mui/lab/TreeView/TreeView";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TreeItem from "@mui/lab/TreeItem/TreeItem";
import treeItemClasses from "@mui/lab/TreeItem/treeItemClasses";
/* Icons */
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { GiPlainCircle } from "react-icons/gi";
import QuizIcon from "@mui/icons-material/Quiz";
import { MdHttps } from "react-icons/md";
import {
	Dashboard,
	Market,
	Store,
	Category,
	Graduation,
	Template,
	Pages,
	User,
	Marketing,
	SalesMan,
	Order,
	Support,
	Price,
	Setting,
	DolarIcon,
	CityIcon,
	Email,
	ReportIcon,
	Shipping,
	PaymentGateway,
	ReviewsIcon,
} from "../../assets/Icons";
import Context from "../../store/context";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		flexDirection: "row-reverse",
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightNormal,
		padding: "0.5rem 0 0.5rem 0.5rem",
		width: "100% !important",
		height: "60px",
		"&.Mui-expanded": {
			fontWeight: theme.typography.fontWeightNormal,
		},
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
			borderRadius: "8px",
		},
		"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			color: "var(--tree-view-color)",
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "inherit",
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

function StyledTreeItem(props) {
	const {
		bgColor,
		color,
		svg,
		labelIcon: LabelIcon,
		labelText,
		number,
		...other
	} = props;

	return (
		<StyledTreeItemRoot
			label={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						p: 0.5,
						pr: 0,
					}}>
					{LabelIcon && (
						<img
							src={LabelIcon}
							color='inherit'
							style={{ height: "1.2rem", zIndex: "10" }}
							alt=''
							loading='lazy'
						/>
					)}
					{svg}
					<Typography
						variant='body2'
						className='whitespace-nowrap'
						sx={{
							fontWeight: "400",
							flexGrow: 1,
							fontSize: "0.9rem",
							fontFamily: "Tajawal, sans-serif !important",
						}}>
						{labelText}
					</Typography>
					{number && (
						<div
							className='flex flex-col items-center justify-center opacity-0 number'
							style={{
								width: "25px",
								height: "25px",
								backgroundColor: "#B6BE34",
								borderRadius: "50%",
							}}>
							<span style={{ fontSize: "16px", color: "#F7FCFF" }}>
								{number}
							</span>
						</div>
					)}
				</Box>
			}
			style={{
				"--tree-view-color": color,
				"--tree-view-bg-color": bgColor,
				margin: "0.25rem 0",
			}}
			{...other}
		/>
	);
}

StyledTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired,
};

const SideBar = ({ openSidebar, closeSidebar, closeSidebarOnly }) => {
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const BackDrop = ({ closeSidebarOnly }) => {
		return (
			openSidebar && (
				<div
					onClick={closeSidebarOnly}
					className='fixed back_drop top-0 left-0 md:h-[0px] h-[75px] w-full z-50'></div>
			)
		);
	};
	const [expanded, setExpanded] = useState(["1"]);
	const handleExpanding = (id) => () => {
		setExpanded([id]);
	};

	return (
		<>
			<BackDrop
				closeSidebarOnly={() => {
					closeSidebarOnly();
				}}></BackDrop>
			<Box
				sx={{
					"&  path": {
						fill: "#02466A",
					},
					"&  .Mui-expanded .MuiTreeItem-iconContainer": {
						display: "none",
					},
					"&  .Mui-expanded .MuiTreeItem-iconContainer path": {
						fill: "#fff",
					},
					"&  .Mui-expanded .MuiTreeItem-label path, .single_link .Mui-selected .MuiTreeItem-label path":
						{
							fill: "#1DBBBE",
						},
					"&  .Mui-expanded .MuiTreeItem-label g, .single_link .Mui-selected .MuiTreeItem-label g":
						{
							fill: "#1DBBBE",
						},
				}}
				className={`${styles.sidebar} ${openSidebar ? styles.active : ""}`}>
				<TreeView
					aria-label='file system navigator'
					defaultCollapseIcon={<IoIosArrowDown />}
					defaultExpandIcon={<IoIosArrowBack />}
					expanded={expanded}
					sx={{
						flexGrow: 1,
						maxWidth: 400,
						overflowY: "auto",
						overflowX: "hidden",
						"& .MuiTreeItem-group .MuiTreeItem-content": {
							paddingLeft: 0,
						},
						"& .MuiTypography-root": {
							color: "#02466A",
							fontSize: "18px",
							fontWeight: 400,
							paddingRight: "10px",
						},
						"& .Mui-expanded .MuiTypography-root , & .single_link .Mui-selected .MuiTypography-root":
							{
								color: "#fff",
							},
						"& > .MuiTreeItem-root .MuiTreeItem-root ": {
							backgroundColor: "#C0E9FF",
							margin: "0.25rem 0",
							borderRadius: "8px",
							padding: "0.25rem 1rem",
						},
						"& > a .MuiTreeItem-root:has(.Mui-expanded) .MuiCollapse-root .MuiTreeItem-root ":
							{
								backgroundColor: "#C0E9FF",
								margin: "0.25rem 0",
								borderRadius: "8px",
								padding: "0.25rem 1rem 0.25rem 12px",
							},
						"& > .MuiTreeItem-root .Mui-expanded.MuiTreeItem-content": {
							width: "100%",
							backgroundColor: "#02466A !important",
							color: "#fff !important",
							transition: "0.1s",
							borderRight: "6px solid #1DBBBE",
							borderRadius: "8px",
							paddingRight: "2px",
						},
						"& .MuiTreeItem-root .MuiTreeItem-label": {
							fontFamily: "Tajawal, sans-serif !important",
						},
						"& > .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper li:has(> .MuiTreeItem-content.Mui-selected) , & > a .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper li:has(> .MuiTreeItem-content.Mui-selected)":
							{
								backgroundColor: "#1DBBBE",
							},
						"& > .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content.Mui-selected,& > .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content.Mui-focused ":
							{
								backgroundColor: "transparent !important",
							},
						"& > a .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content.Mui-selected":
							{
								backgroundColor: "transparent !important",
							},
						"& > a .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content.Mui-selected .number":
							{
								opacity: "1 !important",
							},
						"& > .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content:hover,& > a .MuiTreeItem-root .MuiCollapse-root .MuiCollapse-wrapper .MuiTreeItem-content:hover ":
							{
								backgroundColor: "transparent",
							},
						"& > a .MuiTreeItem-root .Mui-expanded.MuiTreeItem-content , & > a.single_link .MuiTreeItem-root .Mui-selected.MuiTreeItem-content":
							{
								backgroundColor: "#02466A !important",
								color: "#fff !important",
								transition: "0.1s",
								borderRight: "6px solid #1DBBBE",
								borderRadius: "8px",
								paddingRight: "2px",
							},
					}}>
					{(permissions?.includes("admin.mainpage") ||
						permissions?.includes("admin.mainpage.index")) && (
						<Link
							className='single_link'
							onClick={() => {
								closeSidebar();
							}}
							to={"/"}>
							<StyledTreeItem
								nodeId='1'
								onClick={handleExpanding("1")}
								labelText='الرئيسية'
								svg={<Dashboard style={{ height: "1.2rem", zIndex: "10" }} />}
							/>
						</Link>
					)}
					{(permissions?.includes("admin.etlobha") ||
						permissions?.includes("admin.etlobha.index") ||
						permissions?.includes("admin.adminOrder") ||
						permissions?.includes("admin.adminOrder.index") ||
						permissions?.includes("admin.platform") |
							permissions?.includes("admin.platform.index") ||
						permissions?.includes("admin.stock") ||
						permissions?.includes("admin.stock.index")) && (
						<StyledTreeItem
							nodeId='2'
							onClick={handleExpanding("2")}
							labelText='السوق'
							svg={<Market style={{ height: "1.2rem", zIndex: "10" }} />}>
							{(permissions?.includes("admin.etlobha") ||
								permissions?.includes("admin.etlobha.index")) && (
								<Link
									onClick={() => {
										closeSidebar();
									}}
									to={"/souq_atlbha"}>
									<StyledTreeItem
										className='pr-5'
										nodeId='33'
										labelText='سوق المنصة'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
									/>
								</Link>
							)}

							{(permissions?.includes("admin.adminOrder") ||
								permissions?.includes("admin.adminOrder.index")) && (
								<Link
									to={"/talabat_souq_atlbha"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										nodeId='34'
										labelText='طلبات سوق المنصة'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
									/>
								</Link>
							)}
							{(permissions?.includes("admin.platform") ||
								permissions?.includes("admin.platform.index")) && (
								<Link
									to={"/general_souq"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										nodeId='3004'
										labelText='السوق العام'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}></StyledTreeItem>
								</Link>
							)}
							{(permissions?.includes("admin.stock") ||
								permissions?.includes("admin.stock.index")) && (
								<Link
									to={"/storage"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										nodeId='35'
										labelText='المخزون'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
									/>
								</Link>
							)}
						</StyledTreeItem>
					)}
					{(permissions?.includes("admin.store") ||
						permissions?.includes("admin.store.index")) && (
						<Link to={"/markets_section"}>
							<StyledTreeItem
								onClick={handleExpanding("4")}
								sx={{
									"& .MuiCollapse-root": {
										transitionDuration: "3s ",
									},
								}}
								nodeId='4'
								labelText='قسم المتاجر'
								svg={<Store style={{ height: "1.2rem", zIndex: "10" }} />}>
								{(permissions?.includes("admin.store") ||
									permissions?.includes("admin.store.index")) && (
									<Link
										to={"/markets"}
										onClick={() => {
											closeSidebar();
										}}>
										<StyledTreeItem
											className='pr-5'
											svg={
												<GiPlainCircle
													style={{
														position: "absolute",
														right: "-10px",
														height: "1rem",
														width: "1rem",
														zIndex: "10",
													}}
												/>
											}
											nodeId='6'
											labelText='المتاجر'
										/>
									</Link>
								)}
								{(permissions?.includes("admin.product") ||
									permissions?.includes("admin.product.index")) && (
									<Link
										to={"/products"}
										onClick={() => {
											closeSidebar();
										}}>
										<StyledTreeItem
											className='pr-5'
											svg={
												<GiPlainCircle
													style={{
														position: "absolute",
														right: "-10px",
														height: "1rem",
														width: "1rem",
														zIndex: "10",
													}}
												/>
											}
											nodeId='7'
											labelText='المنتجات'
										/>
									</Link>
								)}
								{(permissions?.includes("admin.verification") ||
									permissions?.includes("admin.verification.index")) && (
									<Link
										to={"/verification"}
										onClick={() => {
											closeSidebar();
										}}>
										<StyledTreeItem
											className='pr-5'
											svg={
												<GiPlainCircle
													style={{
														position: "absolute",
														right: "-10px",
														height: "1rem",
														width: "1rem",
														zIndex: "10",
													}}
												/>
											}
											nodeId='8'
											number={0 || localStorage?.getItem("verification_count")}
											labelText='التوثيق'
										/>
									</Link>
								)}
							</StyledTreeItem>
						</Link>
					)}

					{(permissions?.includes("admin.package") ||
						permissions?.includes("admin.package.index")) &&
					(permissions?.includes("admin.plan") ||
						permissions?.includes("admin.plan.index")) &&
					(permissions?.includes("admin.subscriptions") ||
						permissions?.includes("admin.subscriptions.index")) &&
					(permissions?.includes("admin.trip") ||
						permissions?.includes("admin.trip.index")) ? (
						<Link to={"/packages"}>
							<StyledTreeItem
								sx={{
									"& .MuiCollapse-root": {
										transitionDuration: "3s ",
									},
								}}
								onClick={handleExpanding("9")}
								nodeId='9'
								labelText='الباقات'
								svg={<Price style={{ height: "1.2rem", zIndex: "10" }} />}>
								{(permissions?.includes("admin.plan") ||
									permissions?.includes("admin.plan.index")) && (
									<Link
										to={"/packages/package-features"}
										onClick={() => {
											closeSidebar();
										}}>
										<StyledTreeItem nodeId='10909' labelText='خطط الباقات' />
									</Link>
								)}

								{(permissions?.includes("admin.subscriptions") ||
									permissions?.includes("admin.subscriptions.index")) && (
									<Link
										to={"/subscriptions"}
										onClick={() => {
											closeSidebar();
										}}>
										<StyledTreeItem
											nodeId='10'
											svg={
												<DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />
											}
											labelText='الاشتراكات الحالية'
										/>
									</Link>
								)}
							</StyledTreeItem>
						</Link>
					) : permissions?.includes("admin.plan") ||
					  permissions?.includes("admin.plan.index") ? (
						<Link
							className='single_link'
							to={"/packages/package-features"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='10909'
								labelText='خطط الباقات'
								onClick={handleExpanding("")}
								svg={<DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />}
							/>
						</Link>
					) : permissions?.includes("admin.subscriptions") ||
					  permissions?.includes("admin.subscriptions.index") ? (
						<Link
							to={"/subscriptions"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='10'
								svg={<DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />}
								labelText='الاشتراكات الحالية'
							/>
						</Link>
					) : null}

					{(permissions?.includes("admin.service") ||
						permissions?.includes("admin.service.index")) && (
						<Link
							className='single_link'
							to={"/services"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='11'
								onClick={handleExpanding("")}
								labelText='الخدمات'
								svg={
									<Category style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}

					{(permissions?.includes("admin.course") ||
						permissions?.includes("admin.course.index")) && (
						<Link
							className='single_link'
							to={"/atlbha_academy"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='12'
								onClick={handleExpanding("")}
								labelText='أكاديمية المنصة'
								svg={
									<Graduation style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}
					{(permissions?.includes("admin.homepage") ||
						permissions?.includes("admin.homepage.index")) && (
						<Link to={"/template_page"}>
							<StyledTreeItem
								sx={{
									"& .MuiCollapse-root": {
										transitionDuration: "3s ",
									},
								}}
								nodeId='13'
								labelText='القالب'
								onClick={handleExpanding("13")}
								svg={<Template style={{ height: "1.2rem", zIndex: "10" }} />}>
								<Link
									to={"/sliders"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='14'
										labelText='السلايدر'
									/>
								</Link>
								<Link
									to={"/partitions"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='15'
										labelText='التقسيم'
									/>
								</Link>
							</StyledTreeItem>
						</Link>
					)}
					{(permissions?.includes("admin.page") ||
						permissions?.includes("admin.page.index")) &&
					(permissions?.includes("admin.commonquestion") ||
						permissions?.includes("admin.commonquestion.index")) ? (
						<Link to={"/pages"}>
							<StyledTreeItem
								sx={{
									"& .MuiCollapse-root": {
										transitionDuration: "3s ",
									},
								}}
								nodeId='1234'
								labelText='الصفحات'
								onClick={handleExpanding("1234")}
								svg={<Template style={{ height: "1.2rem", zIndex: "10" }} />}>
								<Link
									to={"/faqs"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<QuizIcon style={{ height: "1.2rem", zIndex: "10" }} />
										}
										nodeId='14'
										labelText='الأسئلة الشائعة'
									/>
								</Link>
							</StyledTreeItem>
						</Link>
					) : permissions?.includes("admin.commonquestion") ||
					  permissions?.includes("admin.commonquestion.index") ? (
						<Link
							className='single_link'
							to={"/faqs"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='51'
								labelText='الأسئلة الشائعة'
								onClick={handleExpanding("")}
								svg={
									<QuizIcon style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					) : permissions?.includes("admin.page") ||
					  permissions?.includes("admin.page.index") ? (
						<Link
							className='single_link'
							to={"/pages"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='16'
								labelText='الصفحات'
								onClick={handleExpanding("")}
								svg={
									<Pages style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					) : null}

					{(permissions?.includes("admin.user") ||
						permissions?.includes("admin.user.index")) && (
						<Link
							className='single_link'
							to={"/users"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='17'
								labelText='المستخدمين'
								onClick={handleExpanding("")}
								svg={
									<User style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}
					{(permissions?.includes("admin.coupon") ||
						permissions?.includes("admin.coupon.index")) && (
						<StyledTreeItem
							sx={{
								"& .MuiCollapse-root": {
									transitionDuration: "3s ",
								},
							}}
							nodeId='18'
							labelText='التسويق'
							onClick={handleExpanding("18")}
							svg={<Marketing style={{ height: "1.2rem", zIndex: "10" }} />}>
							{(permissions?.includes("admin.coupon") ||
								permissions?.includes("admin.coupon.index")) && (
								<Link
									to={"/coupons"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='19'
										labelText='كوبونات الخصم'
									/>
								</Link>
							)}
						</StyledTreeItem>
					)}
					{(permissions?.includes("admin.marketer") ||
						permissions?.includes("admin.marketer.index")) && (
						<StyledTreeItem
							sx={{
								"& .MuiCollapse-root": {
									transitionDuration: "3s ",
								},
							}}
							nodeId='20'
							labelText='المندوبين'
							onClick={handleExpanding("20")}
							svg={<SalesMan style={{ height: "1.2rem", zIndex: "10" }} />}>
							{(permissions?.includes("admin.marketer") ||
								permissions?.includes("admin.marketer.index")) && (
								<Link
									to={"/marketers"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='21'
										labelText='عرض المناديب'
									/>
								</Link>
							)}
							{permissions?.includes(
								"admin.marketer.registration_marketer_show"
							) && (
								<Link
									to={"/registration_marketers_status"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='22'
										labelText='حالة التسجيل'
									/>
								</Link>
							)}
						</StyledTreeItem>
					)}
					{(permissions?.includes("admin.category") ||
						permissions?.includes("admin.category.index")) && (
						<Link
							className='single_link'
							to={"/categories_activities"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='23'
								labelText='الأنشطة'
								onClick={handleExpanding("")}
								svg={
									<Order style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}

					{(permissions?.includes("admin.websiteorder") ||
						permissions?.includes("admin.websiteorder.index")) &&
					(permissions?.includes("admin.WebsiteDomainOrder") ||
						permissions?.includes("admin.WebsiteDomainOrder.index")) ? (
						<Link to={"/orders"}>
							<StyledTreeItem
								sx={{
									"& .MuiCollapse-root": {
										transitionDuration: "3s ",
									},
								}}
								nodeId='9238480398023'
								labelText='الطلبات'
								onClick={handleExpanding("9238480398023")}
								svg={<Template style={{ height: "1.2rem", zIndex: "10" }} />}>
								<Link
									to={"/domain-orders"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={<MdHttps style={{ height: "1.2rem", zIndex: "10" }} />}
										nodeId='10999834294'
										labelText='طلبات الدومين'
									/>
								</Link>
							</StyledTreeItem>
						</Link>
					) : permissions?.includes("admin.WebsiteDomainOrder") ||
					  permissions?.includes("admin.WebsiteDomainOrder.index") ? (
						<Link
							className='single_link'
							to={"/domain-orders"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='50923903921'
								labelText='طلبات الدومين'
								onClick={handleExpanding("")}
								svg={<MdHttps style={{ height: "1.2rem", zIndex: "10" }} />}
							/>
						</Link>
					) : permissions?.includes("admin.websiteorder") ||
					  permissions?.includes("admin.websiteorder.index") ? (
						<Link
							className='single_link'
							to={"/orders"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='9302984029740382783'
								labelText='الطلبات'
								onClick={handleExpanding("")}
								svg={<Pages style={{ height: "1.2rem", zIndex: "10" }} />}
							/>
						</Link>
					) : null}
					{(permissions?.includes("admin.technicalsupport") ||
						permissions?.includes("admin.technicalsupport.index")) && (
						<Link
							className='single_link'
							to={"/technical_support"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='27'
								labelText='الدعم الفنى'
								onClick={handleExpanding("")}
								svg={
									<Support style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}
					{/*(permissions?.includes("admin.atlobhaContact") ||
						permissions?.includes("admin.atlobhaContact.index")) && (
						<Link
							className='single_link'
							to={"/contact_us"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='28'
								labelText='تواصل معنا'
								onClick={handleExpanding("")}
								svg={
									<MarkEmailReadIcon
										style={{ height: "1.2rem", zIndex: "10" }}
									/>
								}></StyledTreeItem>
						</Link>
							)*/}
					{(permissions?.includes("admin.shippingtype") ||
						permissions?.includes("admin.shippingtype.index")) && (
						<Link
							className='single_link'
							to={"/shipping_companies"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='29'
								labelText='شركات الشحن'
								onClick={handleExpanding("")}
								svg={
									<Shipping style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}
					{(permissions?.includes("admin.paymenttype") ||
						permissions?.includes("admin.paymenttype.index")) && (
						<Link
							className='single_link'
							to={"/payment_gates"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='30'
								labelText='بوابات الدفع'
								onClick={handleExpanding("")}
								svg={
									<PaymentGateway style={{ height: "1.2rem", zIndex: "10" }} />
								}></StyledTreeItem>
						</Link>
					)}
					{(permissions?.includes("admin.setting") ||
						permissions?.includes("admin.setting.index") ||
						permissions?.includes("admin.country") ||
						permissions?.includes("admin.country.index") ||
						permissions?.includes("admin.city") ||
						permissions?.includes("admin.city.index") ||
						permissions?.includes("admin.currency") ||
						permissions?.includes("admin.currency.index") ||
						permissions?.includes("admin.email") ||
						permissions?.includes("admin.email.index") ||
						permissions?.includes("admin.websitesocialmedia") ||
						permissions?.includes("admin.websitesocialmedia.index") ||
						permissions?.includes("admin.registration.show") ||
						permissions?.includes("admin.registration.update") ||
						permissions?.includes("admin.notification") ||
						permissions?.includes("admin.notification.index") ||
						permissions?.includes("admin.seo") ||
						permissions?.includes("admin.seo.index")) && (
						<StyledTreeItem
							sx={{
								"& .MuiCollapse-root": {
									transitionDuration: "3s ",
								},
							}}
							nodeId='31'
							labelText='الإعدادات'
							onClick={handleExpanding("31")}
							svg={<Setting style={{ height: "1.2rem", zIndex: "10" }} />}>
							{(permissions?.includes("admin.setting") ||
								permissions?.includes("admin.setting.index")) && (
								<Link
									to={"/basic_settings"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='32'
										labelText='الاعدادات الأساسية'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.country") ||
								permissions?.includes("admin.country.index")) && (
								<Link
									to={"/countries"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										svg={
											<CityIcon style={{ height: "1.2rem", zIndex: "10" }} />
										}
										nodeId='36'
										labelText='الدول'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.city") ||
								permissions?.includes("admin.city.index")) && (
								<Link
									to={"/cities"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										svg={
											<CityIcon style={{ height: "1.2rem", zIndex: "10" }} />
										}
										nodeId='37'
										labelText='المدن'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.currency") ||
								permissions?.includes("admin.currency.index")) && (
								<Link
									to={"/currencies"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										svg={
											<DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />
										}
										nodeId='40'
										labelText='العملات'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.email") ||
								permissions?.includes("admin.email.index")) && (
								<Link
									to={"/emails"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										svg={<Email style={{ height: "1.2rem", zIndex: "10" }} />}
										nodeId='41'
										labelText='البريد'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.setting") ||
								permissions?.includes("admin.setting.index")) && (
								<Link
									to={"/"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										svg={
											<ReportIcon style={{ height: "1.2rem", zIndex: "10" }} />
										}
										nodeId='42'
										labelText='التقارير'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.websitesocialmedia") ||
								permissions?.includes("admin.websitesocialmedia.index")) && (
								<Link
									to={"/social_media"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='43'
										labelText='التواصل الإجتماعي'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.registration.show") ||
								permissions?.includes("admin.registration.update")) && (
								<Link
									to={"/registrations"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='44'
										labelText='حالات التسجيل'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.notification") ||
								permissions?.includes("admin.notification.index")) && (
								<Link
									to={"/notifications"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='45'
										labelText='الاشعارات'
									/>
								</Link>
							)}
							{(permissions?.includes("admin.seo") ||
								permissions?.includes("admin.seo.index")) && (
								<Link
									to={"/seo_setting"}
									onClick={() => {
										closeSidebar();
									}}>
									<StyledTreeItem
										className='pr-5'
										svg={
											<GiPlainCircle
												style={{
													position: "absolute",
													right: "-10px",
													height: "1rem",
													width: "1rem",
													zIndex: "10",
												}}
											/>
										}
										nodeId='46'
										labelText='إعدادات SEO'
									/>
								</Link>
							)}
						</StyledTreeItem>
					)}
					{(permissions?.includes("admin.comment") ||
						permissions?.includes("admin.comment.index")) && (
						<Link
							className='single_link'
							to={"/reviews"}
							onClick={() => {
								closeSidebar();
							}}>
							<StyledTreeItem
								nodeId='50'
								labelText='تقييمات المنصة'
								onClick={handleExpanding("")}
								svg={
									<ReviewsIcon
										style={{
											minWidth: "24px",
											minHeight: "24px",
											zIndex: "10",
										}}
									/>
								}></StyledTreeItem>
						</Link>
					)}
				</TreeView>
			</Box>
		</>
	);
};

export default SideBar;
