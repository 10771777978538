import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import ShopVarietiesTable from "../../../components/VarietiesComp/ShopVarieties/ShopVarietiesTable/ShopVarietiesTable";
import Button from "../../../UI/Button/Button";
import { AiOutlinePlus } from "react-icons/ai";
import {
	useFilterCategoryMutation,
	useGetCategoriesQuery,
} from "../../../RTK/apiSlices/categoriesApi";
import VarietiesFilterForm from "../../../components/VarietiesComp/ShopVarieties/VarietiesFilterForm";

const ShopVarieties = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [resultData, setResultData] = useState();
	const [data, setData] = useState();
	const [category, setCategory] = useState("");
	const [subCategory, setSubCategory] = useState([]);
	const [filterResults, setFilterResults] = useState([]);

	const { data: fetchedData, isLoading: loading } = useGetCategoriesQuery({
		page,
		number: rowsPerPage,
	});
	const subcategory =
		fetchedData?.categories?.filter((sub) => sub?.id === parseInt(category)) ||
		"";

	useEffect(() => {
		setResultData(fetchedData?.categories);
		setData(resultData);
	}, [fetchedData?.categories, resultData]);

	const [filterCategory, { isLoading: filterIsLoading }] =
		useFilterCategoryMutation();
	const filterCategoryHandel = async () => {
		try {
			const response = await filterCategory({
				page,
				number: rowsPerPage,
				id: category,
			});

			setFilterResults(response?.data?.data);

			const responseData = response.data?.data;
			if (responseData) {
				setData(responseData.categories);
			}
		} catch (error) {
			console.error("Error filtering orders:", error);
		}
	};

	const onClickFilter = () => {
		if (subCategory?.length !== 0 && category !== "") {
			filterCategoryHandel();
		} else {
			setData(resultData);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | الأنشطة</title>
			</Helmet>
			<div className='xl:px-4 2xl:pl-36 p-4 xl:bg-[#F6F6F6] bg-[#FFFFFF]'>
				<div className='flex flex-col items-start justify-between gap-4 mt-4 md:mt-6 md:flex-row md:items-center'>
					<PageNavigate currentPage={"الأنشطة"} />
					{permissions?.includes("admin.category.store") && (
						<Button
							className='md:w-[300px] w-full md:h-[56px] h-[45px] md:text-[20px] text-[18px] flex justify-center items-center'
							type={"normal"}
							svg={<AiOutlinePlus color='#fff' />}
							color={"white"}
							style={{
								backgroundColor: "#B6BE34",
								width: "200px",
								height: "57px",
							}}
							textStyle={{ color: "#EFF9FF", fontSize: "21px" }}
							onClick={() => {
								navigate("/categories_activities/add-category");
							}}>
							اضف نشاط أو تصنيف
						</Button>
					)}
				</div>
				{/** --------------------------------------------------------------- */}
				<VarietiesFilterForm
					category={category}
					setCategory={setCategory}
					fetchedData={fetchedData}
					onClickFilter={onClickFilter}
					subcategory={subcategory}
					subCategory={subCategory}
					setSubCategory={setSubCategory}
				/>

				{/** categories and activities Table  */}
				<div dir='ltr' className='mt-6 md:mt-10'>
					<ShopVarietiesTable
						fetchedData={data}
						loading={loading || filterIsLoading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
						isCategory={category}
					/>
				</div>
			</div>
		</>
	);
};

export default ShopVarieties;
