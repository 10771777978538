import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "../TrainingCourses/AddNewCourse/AddNewCourse.module.css";

/* Third party */
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";

// Context
import Context from "../../../store/context";
import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";

// Components
import { TextEditor } from "../../TextEditor";
import Button from "../../../UI/Button/Button";

// Icons
import { GoArrowRight } from "react-icons/go";
import { IoMdCloudUpload } from "react-icons/io";

// RTK query
import { useAddNewLiveCourseMutation } from "../../../RTK/apiSlices/coursesApi";

const AddNewLiveCourse = () => {
	const navigate = useNavigate();

	const contextStore = useContext(Context);
	const { setSelectedId } = contextStore;

	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			link: "",
		},
	});

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	// upload course image
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const ref = useRef(null);

	// errors
	const [dataError, setDataError] = useState({
		name: "",
		description: "",
		image: "",
		link: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			description: "",
			image: "",
			link: "",
		});
	};

	const handleClosePage = () => {
		navigate("/atlbha_academy");
		setEditorValue("");
		setSelectedId(2);
	};

	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...dataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...dataError, image: null });
		}
	};

	const [addNewLiveCourse] = useAddNewLiveCourseMutation();
	const addNewCourseHandel = async (formData) => {
		try {
			const res = await addNewLiveCourse({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					description: res?.data?.message?.en?.description?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleCourse = (dataCourse) => {
		resetData();
		const formData = new FormData();
		formData.append("name", dataCourse?.name);
		formData.append("description", editorValue);

		if (images.length !== 0) {
			formData.append("image", images[0]?.file || "");
		}
		formData.append("link", dataCourse.link);

		setLoadingTitle("جاري حفظ الدورة");
		addNewCourseHandel(formData);
	};

	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | اضافة دورة مباشرة</title>
			</Helmet>
			<form className='' onSubmit={handleSubmit(handleCourse)}>
				<div className=' absolute lg:pl-[140px] md:pr-5 md:py-[43px] md:pt-4 top-0 right-0 z-30 md:pb-36 w-full md:bg-[#fafafa] bg-[#FFFFFF] otlobha_acadmic'>
					<div
						ref={ref}
						className='flex items-center justify-between px-4 pt-6 mb-2 md:p-0 md:pt-0'>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={handleClosePage}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#02466A", fontSize: "1.2rem" }}
									/>
								</div>

								<Link
									to={"/"}
									className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2'>
									الرئيسية
								</Link>
							</div>

							<h2
								onClick={handleClosePage}
								className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2 cursor-pointer'>
								/ أكاديمية المنصة
							</h2>

							<h3
								className='md:text-[18px] text-[15px] font-medium'
								style={{ color: "#67747B" }}>
								/ اضافة دورة مباشرة
							</h3>
						</div>
					</div>
					<div className='flex flex-col gap-5 mt-[42px] md:pr-[78px] p-4 h-full'>
						<div className='flex flex-col gap-[10px]'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								اسم الدورة المباشرة<span className='text-red-500'>*</span>
							</label>
							<input
								className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
								style={{
									backgroundColor: "#F4F5F7",
									border: "1px solid #67747B33",
								}}
								type='text'
								name='name'
								{...register("name", {
									required: "حقل الاسم مطلوب",
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{dataError?.name}
								{errors?.name && errors.name.message}
							</span>
						</div>

						<div className='flex flex-col gap-[10px]'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								الوصف<span className='text-red-500'>*</span>
							</label>
							<div className={styles.editor}>
								<TextEditor
									ToolBar={"createOrEditPages"}
									placeholder={" وصف الدورة المباشرة..."}
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{dataError?.description}
							</span>
						</div>

						<div className='flex flex-col items-start md:flex-row md:items-center md:gap-16 gap-y-4'>
							<label
								className='md:text-[18px] text-[16px] font-medium whitespace-nowrap'
								style={{ color: "#011723" }}>
								الصورة التعريفية<span className='text-red-500'>*</span>
							</label>
							<div className='flex flex-col w-full gap-2'>
								<div className='text-gray-400 font-light text-[16px]'>
									المقاس المقترح : 440 * 440
								</div>
								<ImageUploading
									value={images}
									onChange={onChange}
									maxNumber={2}
									dataURLKey='data_url'
									acceptType={["jpg", "png", "jpeg", "webp"]}>
									{({ onImageUpload, dragProps }) => (
										// write your building UI
										<div
											className='relative mx-auto overflow-hidden upload__image-wrapper'
											style={{
												width: "100%",
												padding: "8px",
												border: "1px dashed #02466A",
												borderRadius: "10px",
												strokeDasharray: "'6%2c5'",
											}}
											onClick={() => {
												onImageUpload();
											}}
											{...dragProps}>
											<div className='w-full h-full cursor-pointer image-item'>
												<div className='flex flex-col justify-center items-center gap-[5px] h-full w-full'>
													<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
													<h2 style={{ color: "#011723", fontSize: "16px" }}>
														اسحب الصورة هنا
													</h2>
													<div className='flex flex-col items-center gap-1'>
														<h2 className='mb-0'>
															(سيتم قبول الصور png & jpg & jpeg & webp)
														</h2>
														<span className='text-gray-400 font-light text-[14px]'>
															(الحد الأقصى للصورة 1MB)
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</ImageUploading>
								{dataError?.image && (
									<span className='text-red-500 text-[16px]'>
										{dataError?.image}
									</span>
								)}
							</div>
						</div>

						<div className='flex flex-col items-start justify-center md:flex-row md:gap-16 gap-y-4'>
							{images[0] && (
								<div className='md:w-[400px] w-[100%] md:h-[400px] h-[100%] mt-4'>
									<img
										className='mx-auto my-0'
										src={images[0]?.data_url}
										alt='preview-img'
										loading='lazy'
									/>
								</div>
							)}
						</div>

						<div className='flex flex-col gap-[10px]'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								رابط الدورة المباشرة<span className='text-red-500'>*</span>
							</label>
							<input
								className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
								style={{
									backgroundColor: "#F4F5F7",
									border: "1px solid #67747B33",
								}}
								type='text'
								name='link'
								{...register("link", {
									required: "حقل الرابط مطلوب",
									pattern: {
										value:
											/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
										message: "يرجى إدخال رابط صحيح",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{dataError?.name}
								{errors?.name && errors.name.message}
							</span>
						</div>

						<>
							<div className='flex gap-4 mt-10'>
								<Button
									btnType={"submit"}
									style={{ width: "100%", backgroundColor: "#1DBBBE" }}
									textStyle={{ color: "#EFF9FF" }}
									className={
										"md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"normal"}>
									حفظ الدورة
								</Button>
								<Button
									style={{ borderColor: "#02466A" }}
									textStyle={{ color: "#02466A" }}
									className={
										"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"outline"}
									onClick={handleClosePage}
									btnType={"button"}>
									إلغاء
								</Button>
							</div>
						</>
					</div>
				</div>
			</form>
		</>
	);
};

export default AddNewLiveCourse;
