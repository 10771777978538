import React, { useEffect, useContext, useState } from "react";
import styles from "./MarketsTableSec.module.css";
import PropTypes from "prop-types";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Third party */
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
/* Context */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import DynamicMenu from "../../../DynamicMenu/DynamicMenu";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";
/* Icons */
import { BiSolidDoorOpen } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosDownload } from "react-icons/io";
import { MdAutorenew } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import {
	CheckedSquare,
	EditButton,
	InfoIcon,
	SwitchOn,
	Delete,
	SortIcon,
	SendNote,
} from "../../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../../PaginationComp/NewPagination";
import {
	useChangeMarketStatusByIdMutation,
	useChangeMarketStatusMutation,
	useChangeSpecialStatusMutation,
	useLoginToStoreMutation,
	useMarketDeleteItemMutation,
	useSearchInMarketsMutation,
} from "../../../../RTK/apiSlices/marketsApi";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";
import { toast } from "react-toastify";
// -------------------------------------

// switch styles
const switchStyle = {
	width: "26px",
	padding: "12px 0",

	"& .MuiSwitch-thumb": {
		width: "10px",
		height: "10px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "6px",
		top: "9px",
		left: "-4px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-12px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#FFFFFF",
	},
	"& .MuiSwitch-track": {
		height: "16px",
		borderRadius: "20px",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",
		opacity: 1,
	},
};

// tooltip styles
const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#1DBBBE",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#1DBBBE",
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, id) => [el, id]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const verificationStoreHeadCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "last_login",
		numeric: true,
		disablePadding: false,
		label: "آخر زيارة",
		sort: true,
		textAlign: "center",
		sortLabel: "last_login",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "المدة المتبقية",
		sort: true,
		textAlign: "center",
		sortLabel: "left",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "مميز",
		sort: true,
		textAlign: "center",
		sortLabel: "special",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "status",
	},

	{
		id: "created_at",
		numeric: true,
		disablePadding: false,
		label: "تاريخ الانشاء",
		sort: true,
		textAlign: "center",
		sortLabel: "created_at",
	},

	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
	},

	{
		id: "name",
		numeric: true,
		textAlign: "center",
		disablePadding: false,
		label: "رقم الجوال",
	},

	{
		id: "name",
		numeric: true,
		textAlign: "center",
		disablePadding: false,
		label: "اسم المتجر",
	},
	{
		id: "number",
		numeric: true,
		textAlign: "left",
		disablePadding: true,
		label: "م",
	},
];
const unVerificationStoreHeadCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "المدة المتبقية",
		sort: true,
		textAlign: "center",
		sortLabel: "left",
	},

	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "status",
	},

	{
		id: "created_at",
		numeric: true,
		disablePadding: false,
		label: "تاريخ الانشاء",
		sort: true,
		textAlign: "center",
		sortLabel: "created_at",
	},
	{
		id: "name",
		numeric: true,
		textAlign: "center",
		disablePadding: false,
		label: "رقم الجوال",
	},

	{
		id: "name",
		numeric: true,
		textAlign: "center",
		disablePadding: false,
		label: "اسم المتجر",
	},

	{
		id: "number",
		numeric: true,
		textAlign: "center",
		disablePadding: true,
		label: "م",
	},
	{
		textAlign: "center",
		disablePadding: true,
		label: "",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, storesTabs } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#ebebebd9" }}>
			{storesTabs === 1 ? (
				<TableRow>
					{verificationStoreHeadCells?.map((headCell, index) => (
						<TableCell
							className='text-lg font-medium'
							key={index}
							align={headCell.numeric ? "right" : "center"}
							padding={headCell.disablePadding ? "none" : "normal"}
							sortDirection={orderBy === headCell?.id ? order : false}
							sx={{
								width: headCell.width ? headCell.width : "auto",
								color: "#02466A",
								whiteSpace: "nowrap",
								textAlign: headCell?.textAlign,
							}}>
							{headCell?.sort && (
								<TableSortLabel
									IconComponent={() => {
										return <SortIcon />;
									}}
									active={orderBy === headCell?.id}
									direction={orderBy === headCell?.id ? order : "asc"}
									onClick={createSortHandler(headCell?.sortLabel)}>
									{headCell?.label}
									{!orderBy === headCell?.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							)}
							{!headCell?.sort && headCell?.label}
						</TableCell>
					))}
					<TableCell padding={"none"}></TableCell>
				</TableRow>
			) : (
				<TableRow>
					{unVerificationStoreHeadCells?.map((headCell, index) => (
						<TableCell
							className='text-lg font-medium'
							key={index}
							align={headCell.numeric ? "right" : "center"}
							padding={headCell.disablePadding ? "none" : "normal"}
							sortDirection={orderBy === headCell?.id ? order : false}
							sx={{
								width: headCell.width ? headCell.width : "auto",
								color: "#02466A",
								whiteSpace: "nowrap",
								textAlign: headCell?.textAlign,
							}}>
							{headCell?.sort && (
								<TableSortLabel
									IconComponent={() => {
										return <SortIcon />;
									}}
									active={orderBy === headCell?.id}
									direction={orderBy === headCell?.id ? order : "asc"}
									onClick={createSortHandler(headCell?.sortLabel)}>
									{headCell?.label}
									{!orderBy === headCell?.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							)}
							{!headCell?.sort && headCell?.label}
						</TableCell>
					))}
					<TableCell padding={"none"}></TableCell>
				</TableRow>
			)}
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,
		storesTabs,
		selectedToExport,
		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	// handle export excel sheet
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = () => {
		const ws = XLSX.utils.json_to_sheet(
			selectedToExport?.map((item) => ({
				"اسم المتجر ": item?.store_name,
				النشاط: item?.activity?.[0]?.name,
				"المدينة ": item?.city?.name,
				"رقم الجوال": item?.phonenumber,
				" الايميل": item?.store_email,
			}))
		);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, "markets" + fileExtension);
	};

	// ---------------------------------

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 mb-4 md:mb-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			{storesTabs === 1 && (
				<div className={styles.search}>
					<input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder='البحث باسم المتجر - بالدومين - بالايميل '
					/>
					<AiOutlineSearch color='#898989' size={"18px"} />
				</div>
			)}

			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-4 p-0 mb-2 sm:flex-row md:flex-nowrap'>
				<div className='gap-2 px-4 rounded-full fcc'>
					{permissions?.includes("admin.store.changeSatusall") && (
						<>
							{numSelected > 0 && (
								<div
									className='gap-2 px-2 rounded-full fcc'
									style={{ minWidth: "114px", backgroundColor: "#FF9F1A0A" }}
									onClick={() => {
										setNotificationTitle(
											"سيتم إيقاف تنشيط جميع المتاجر التي قمت بتحديدها"
										);
										setItems(itemsSelected);
										setActionType("ChangeStatus");
									}}>
									<h2
										className={"font-medium whitespace-nowrap"}
										style={{ color: "#FF9F1A" }}>
										نشط/ غير نشط
									</h2>
									<Box
										sx={{
											"& #Path_820": {
												fill: "#FF9F1A",
											},
										}}>
										<SwitchOn
											style={{
												cursor: "pointer",
												color: "red",
												fontSize: "0.5rem",
											}}
											className={"w-5"}></SwitchOn>
									</Box>
								</div>
							)}
						</>
					)}
					{permissions?.includes("admin.store.export") && (
						<>
							{numSelected > 0 && (
								<div
									className='fcc gap-2 px-4 rounded-full h-[38px]'
									style={{
										minWidth: "114px",
										backgroundColor: "#0524ff1f",
										cursor: "pointer",
									}}
									onClick={exportToCSV}>
									<IoIosDownload
										style={{ fill: "#1a30ff", fontSize: "20px" }}
									/>
									<h2
										className={"font-medium whitespace-nowrap"}
										style={{ color: "#1a30ff" }}>
										تصدير
									</h2>
								</div>
							)}
						</>
					)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium whitespace-nowrap'>تحديد الكل</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#011723",
							"& .MuiSvgIcon-root": {
								color: "#011723",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	storesTabs,
	openEditModal,
	openTraderAlert,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const navigate = useNavigate();

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [selectedToExport, setSelectedToExport] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let stores = fetchedData?.stores || [];

	if (search !== "") {
		stores = searchResults?.stores;
	} else {
		stores = fetchedData?.stores;
	}

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const NotificationStore = useContext(NotificationContext);

	const { notificationTitle } = NotificationStore;
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = stores?.map((n) => n.id);
			const newSelectedToExport = stores?.map((strore) => strore);
			setSelected(newSelected);
			setSelectedToExport(newSelectedToExport);
			return;
		}
		setSelected([]);
		setSelectedToExport([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleClickToExport = (event, value) => {
		if (event.target.checked) {
			selectedToExport?.push(value);
		} else {
			var index = selectedToExport?.indexOf(value);
			if (index > -1) {
				selectedToExport?.splice(index, 1);
			}
		}
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stores?.length) : 0;

	const [changeMarketStatusById] = useChangeMarketStatusByIdMutation();

	const changeStoreStatus = async (id) => {
		try {
			await changeMarketStatusById({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [changeSpecialStatus] = useChangeSpecialStatusMutation();
	const changeProductSpecialStatus = async (id) => {
		try {
			await changeSpecialStatus({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [changeMarketStatus] = useChangeMarketStatusMutation();

	const changeMarketStatusHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await changeMarketStatus({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [loginToStore] = useLoginToStoreMutation();

	const handleOpenStoreDashboard = async (storeId) => {
		try {
			const res = await loginToStore({ storeId });

			if (res?.data?.status === 200) {
				window.open(`https://store.modelss.co`, "_blank");
			}
		} catch (error) {
			console.error(error);
		}
	};

	const [marketDeleteItem] = useMarketDeleteItemMutation();

	const handleDeleteSingleItem = async (id) => {
		try {
			await marketDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete", err);
		}
	};

	const [searchInMarkets, { isLoading: searchIsLoading }] =
		useSearchInMarketsMutation();
	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInMarkets({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(stores);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected?.length || 0}
					rowCount={stores?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					setSearchResults={setSearchResults}
					storesTabs={storesTabs}
					selectedToExport={selectedToExport}
				/>
				<TableContainer>
					<Table size={"medium"}>
						<EnhancedTableHead
							numSelected={selected?.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={stores?.length}
							storesTabs={storesTabs}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={10}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{stores?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={10}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(stores, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `enhanced-table-checkbox-${index}`;
												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2 '>
																{permissions?.includes(
																	"admin.store.destroy"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف المتجر وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.store.changeSatusall"
																) && (
																	<Switch
																		title='تغيير الحالة'
																		onChange={() => changeStoreStatus(row?.id)}
																		sx={switchStyle}
																		checked={
																			row?.status === "نشط" ? true : false
																		}
																	/>
																)}
																{permissions?.includes("admin.store.show") && (
																	<InfoIcon
																		title=' تفاصيل المتجر'
																		onClick={() => {
																			navigate(
																				`/markets/show-market/${row?.id}`
																			);
																		}}
																		className='cursor-pointer '
																	/>
																)}
																{permissions?.includes(
																	"admin.store.updateProfile"
																) && (
																	<EditButton
																		title='تعديل كلمة المرور المتجر'
																		className='w-6 h-6 cursor-pointer '
																		onClick={() => {
																			navigate(
																				`/markets/edit-market/${row?.id}`
																			);
																		}}
																	/>
																)}

																{permissions?.includes(
																	"admin.store.loginStore"
																) && (
																	<button
																		onClick={() =>
																			handleOpenStoreDashboard(row.id)
																		}>
																		<BiSolidDoorOpen
																			className={` cursor-pointer text-[#1DBBBE] w-6 h-6 `}
																			title={"الدخول إلى لوحة تحكم المتجر"}
																		/>
																	</button>
																)}
																{permissions?.includes(
																	"admin.store.addNote"
																) && (
																	<BootstrapTooltip
																		className={"p-0"}
																		TransitionProps={{ timeout: 300 }}
																		TransitionComponent={Zoom}
																		title={
																			<div className='flex flex-row items-center gap-2'>
																				<MdOutlineEmail
																					title='عبر البريد الالكتروني'
																					className='w-6 h-6 cursor-pointer'
																					onClick={() => {
																						openTraderAlert(row);
																					}}
																				/>
																				<a
																					href={`https://wa.me/${
																						row?.phonenumber?.startsWith(
																							"+966"
																						) ||
																						row?.phonenumber?.startsWith(
																							"00966"
																						)
																							? row?.phonenumber
																							: `+966${row?.phonenumber}`
																					}`}
																					target='_blank'
																					rel='noreferrer'>
																					<FaWhatsapp
																						title='عبر الواتس اب'
																						className='w-6 h-6 cursor-pointer'
																					/>
																				</a>
																			</div>
																		}
																		placement='top-start'>
																		<IconButton>
																			<SendNote
																				title='ارسال ملاحظة'
																				className='w-6 h-6 cursor-pointer'
																			/>
																		</IconButton>
																	</BootstrapTooltip>
																)}
															</div>
														</TableCell>
														{storesTabs === 1 && (
															<TableCell align='center'>
																<div>
																	<h2
																		dir='rtl'
																		className='text-lg font-normal whitespace-nowrap'>
																		<span className='ml-1'>
																			{moment(row?.last_login).format(
																				"YYYY-MM-DD"
																			)}
																		</span>
																	</h2>
																</div>
															</TableCell>
														)}
														<TableCell align='center'>
															<div dir='rtl '>
																{row?.left === 0 ? (
																	<div className='flex  items-center gap-1'>
																		{permissions?.includes(
																			"admin.subscriptions.updateSubscriptionsAll"
																		) &&
																			row?.left <= 30 && (
																				<BootstrapTooltip
																					className='!p-0'
																					TransitionProps={{ timeout: 300 }}
																					TransitionComponent={Zoom}
																					title={<>تعديل مدة الاشتراك</>}
																					placement='top'>
																					<IconButton className='!p-0'>
																						<MdAutorenew
																							className='text-green-500 w-5 h-5 cursor-pointer'
																							onClick={() => {
																								openEditModal(row);
																							}}
																							width={"18px"}
																						/>
																					</IconButton>
																				</BootstrapTooltip>
																			)}
																		<span className='text-red-500 text-xs'>
																			انتهت مدة الإشتراك
																		</span>
																	</div>
																) : row?.left <= 30 ? (
																	<div className='flex  items-center gap-1'>
																		<span className='text-yellow-500 text-xs font-medium'>
																			<div>
																				( متبقي{" "}
																				{row?.left === 1
																					? "يوم واحد"
																					: row?.left === 2
																					? "يومين"
																					: row?.left >= 1 &&
																					  `${row?.left} أيام`}
																				)
																			</div>
																		</span>
																		{permissions?.includes(
																			"admin.subscriptions.updateSubscriptionsAll"
																		) &&
																			row?.left <= 30 && (
																				<BootstrapTooltip
																					className='!p-0'
																					TransitionProps={{ timeout: 300 }}
																					TransitionComponent={Zoom}
																					title={<>تعديل مدة الاشتراك</>}
																					placement='top'>
																					<IconButton className='!p-0'>
																						<MdAutorenew
																							className='text-green-500  w-5 h-5 cursor-pointer'
																							onClick={() => {
																								openEditModal(row);
																							}}
																							width={"18px"}
																						/>
																					</IconButton>
																				</BootstrapTooltip>
																			)}
																	</div>
																) : (
																	<div className='flex items-center justify-center gap-1 text-base text-[#02466A]'>
																		<span>{row?.left}</span>
																		<span>يوم</span>
																	</div>
																)}
															</div>
														</TableCell>
														{storesTabs === 1 &&
															permissions?.includes(
																"admin.store.changeSatusall"
															) && (
																<TableCell align='center'>
																	<Tooltip
																		title={
																			row?.verification_status === "تم التوثيق"
																				? ""
																				: "المتجر غير موثق"
																		}>
																		<div className='flex flex-row items-center justify-end gap-1'>
																			<h2
																				style={{
																					color:
																						row.special === "مميز"
																							? "#3AE374"
																							: "#ADB5B9",
																				}}
																				className='md:text-[16px] text-[14px] min-w-[50px] whitespace-nowrap'>
																				{row?.special}
																			</h2>
																			<Switch
																				onChange={() =>
																					changeProductSpecialStatus(row?.id)
																				}
																				className=''
																				sx={switchStyle}
																				checked={
																					row?.special === "مميز" ? true : false
																				}
																				disabled={
																					row?.verification_status ===
																					"تم التوثيق"
																						? false
																						: true
																				}
																			/>
																		</div>
																	</Tooltip>
																</TableCell>
															)}

														<TableCell align='center'>
															<div
																className='w-20 h-full py-[2px] rounded-2xl mx-auto'
																style={{
																	backgroundColor:
																		row.status === "نشط"
																			? "rgba(58, 227, 116, 0.4)"
																			: "#D3D3D3",
																	marginLeft: "auto",
																}}>
																<h2>{row?.status}</h2>
															</div>
														</TableCell>
														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C" }}>
																{moment(row.created_at).format("YYYY-MM-DD")}
															</h2>
														</TableCell>

														{storesTabs === 1 && (
															<>
																<TableCell
																	align='right'
																	sx={{
																		display: "flex",
																		gap: "0.5rem",
																	}}>
																	<div className='flex flex-row items-center justify-end gap-3 h-[42px]'>
																		{row?.activity?.length > 1 && (
																			<DynamicMenu items={row?.activity} />
																		)}
																		<h2
																			style={{
																				color: "#4D4F5C",
																				overflow: "hidden",
																				textOverflow: "ellipsis",
																				whiteSpace: "nowrap",
																				direction: "rtl",
																				width: "max-content",
																				maxWidth: "140px",
																			}}
																			className='md:text-[16px] text-[14px] inline whitespace-nowrap font-normal'>
																			{row?.activity?.[0]?.name}
																		</h2>
																		{row?.activity?.[0]?.icon && (
																			<div
																				className='img_icons'
																				style={{
																					minWidth: "35px",
																					minHeight: "35px",
																					maxWidth: "35px",
																					maxHeight: "35px",
																				}}>
																				<img
																					src={row?.activity?.[0]?.icon}
																					alt={row?.activity?.[0]?.name}
																					loading='lazy'
																				/>
																			</div>
																		)}
																	</div>
																</TableCell>
															</>
														)}
														<TableCell align='center'>
															{row?.phonenumber && (
																<h2
																	className='md:text-[18px] text-[16px] font-normal'
																	style={{ color: "#4D4F5C" }}>
																	{row?.phonenumber?.startsWith("+966") ||
																	row?.phonenumber?.startsWith("00966")
																		? row?.phonenumber
																		: `+966${row?.phonenumber}`}
																</h2>
															)}
														</TableCell>
														<TableCell align='right' style={{ width: "100px" }}>
															{row?.verification_status === "تم التوثيق" ? (
																<div className='flex flex-row items-center justify-end gap-2 pr-2'>
																	<a
																		href={row?.domain}
																		target='_blank'
																		className='text-lg font-normal'
																		rel='noreferrer'
																		style={{
																			color: "#4D4F5C",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			whiteSpace: "nowrap",
																			direction: "rtl",
																			width: "150px",
																		}}>
																		{row?.store_name}
																	</a>
																	<div
																		className='img_icons'
																		style={{
																			minWidth: "35px",
																			minHeight: "35px",
																			maxWidth: "35px",
																			maxHeight: "35px",
																		}}>
																		<img
																			src={
																				row?.logo ? row?.logo : PlaceholderImage
																			}
																			alt={row?.store_name}
																			loading='lazy'
																			style={{
																				width: "100%",
																				height: "100%",
																				objectFit: "cover",
																				borderRadius: "50%",
																			}}
																		/>
																	</div>
																</div>
															) : (
																<div className='flex flex-row items-center justify-end gap-2 pr-2'>
																	<h2
																		className='text-lg font-normal'
																		style={{
																			color: "#4D4F5C",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			whiteSpace: "nowrap",
																			direction: "rtl",
																			width: "150px",
																		}}>
																		{row?.store_name}
																	</h2>
																	<div
																		className='img_icons'
																		style={{
																			minWidth: "35px",
																			minHeight: "35px",
																			maxWidth: "35px",
																			maxHeight: "35px",
																		}}>
																		<img
																			src={
																				row?.logo ? row?.logo : PlaceholderImage
																			}
																			alt={row?.store_name}
																			loading='lazy'
																			style={{
																				width: "100%",
																				height: "100%",
																				objectFit: "cover",
																				borderRadius: "50%",
																			}}
																		/>
																	</div>
																</div>
															)}
														</TableCell>
														{storesTabs === 1 ? (
															<TableCell padding='none' align={"right"}>
																<div
																	className={` flex justify-start items-center`}>
																	<div className='text-lg font-normal '>
																		{(index + 1).toLocaleString("en-US", {
																			minimumIntegerDigits: 2,
																			useGrouping: false,
																		})}
																	</div>
																	<Checkbox
																		checkedIcon={<CheckedSquare />}
																		sx={{
																			color: "#1DBBBE",
																			"& .MuiSvgIcon-root": {
																				color: "#ADB5B9",
																			},
																		}}
																		checked={isItemSelected}
																		onClick={(event) => {
																			handleClick(event, row?.id);
																			handleClickToExport(event, row);
																		}}
																		inputProps={{
																			"aria-labelledby": labelId,
																		}}
																	/>
																</div>
															</TableCell>
														) : (
															<>
																<TableCell padding='none' align={"center"}>
																	<div className='text-lg font-normal '>
																		{(index + 1).toLocaleString("en-US", {
																			minimumIntegerDigits: 2,
																			useGrouping: false,
																		})}
																	</div>
																</TableCell>
																<TableCell padding='none' align={"right"}>
																	<Checkbox
																		checkedIcon={<CheckedSquare />}
																		sx={{
																			color: "#1DBBBE",
																			"& .MuiSvgIcon-root": {
																				color: "#ADB5B9",
																			},
																		}}
																		checked={isItemSelected}
																		onClick={(event) => {
																			handleClickToExport(event, row);
																			handleClick(event, row?.id);
																		}}
																		inputProps={{
																			"aria-labelledby": labelId,
																		}}
																	/>
																</TableCell>
															</>
														)}
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{!search && (
				<>
					{storesTabs === 1 && (
						<NewPagination
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							page_count={page_count}
						/>
					)}
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleChangeAllItemsStatus={changeMarketStatusHandel}
				/>
			)}
		</Box>
	);
}
