import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LoadingContext } from "../../../store/LoadingProvider";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import Switch from "@mui/material/Switch";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import {
	useGetRegistrationStatusQuery,
	useUpdateRegistrationStatusMutation,
} from "../../../RTK/apiSlices/registrationStatusApi";

const cases = [
	{
		id: 1,
		name: "التسجيل مع موافقة الادارة",
		onclick: "registration_with_admin",
	},
	{ id: 2, name: "ايقاف التسجيل", onclick: "stop_registration" },
	{ id: 3, name: "التسجيل تلقائي", onclick: "registration_without_admin" },
];

const RegistrationCasesPage = () => {
	const { data: fetchedData, isLoading: loading } =
		useGetRegistrationStatusQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [updateRegistrationStatus] = useUpdateRegistrationStatusMutation();
	const updateRegistrationStatusHandel = async (formData) => {
		try {
			const res = await updateRegistrationStatus({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const changeRegistrationStatus = (value) => {
		setLoadingTitle("جاري تغيير حالة التسجيل");
		let formData = new FormData();
		formData.append("registration_status", value);
		updateRegistrationStatusHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | حالات التسجيل</title>
			</Helmet>
			<div
				className={`relative h-full xl:pb-10 2xl:pl-36 xl:pr-5 p-4`}
				style={{ backgroundColor: "#F7F7F7" }}>
				<div className='flex flex-row items-start gap-3 md:items-center'>
					<h3
						style={{ color: "#011723" }}
						className='md:text-[24px] text-[20px] font-bold whitespace-nowrap'>
						حالات التسجيل
					</h3>
					<p
						style={{ color: "#67747B" }}
						className='md:text-[18px] text-[14px] font-medium'>
						(تتيح هذه الواجهة التحكم بحالة التسجيل في الصفحة الرئيسية)
					</p>
				</div>
				<div className='mt-4'>
					<PageNavigate
						nestedPage={true}
						parentPage={"الاعدادات"}
						currentPage={"حالات التسجيل"}
					/>
					<div className='flex flex-row flex-wrap items-center justify-center gap-4 mt-16'>
						{loading ? (
							<CircularLoading />
						) : (
							cases?.map(
								(box, index) =>
									permissions?.includes("admin.registration.update") && (
										<div
											key={index}
											style={{
												width: "280px",
												height: "120px",
												boxShadow: "3px 3px 6px #0000000A",
												backgroundColor:
													box?.onclick === fetchedData?.registration_status
														? "#DDF9E7"
														: "#E6E6E6",
											}}
											className='flex flex-col items-center justify-center gap-[18px] p-8 rounded-lg'>
											<h2
												style={{ fontSize: "20px", color: "#011723" }}
												className='font-medium whitespace-nowrap'>
												{box.name}
											</h2>
											<Switch
												title='تغيير الحالة'
												onChange={() => changeRegistrationStatus(box?.onclick)}
												sx={{
													width: "32px",
													height: "20px",
													padding: 0,
													borderRadius: "12px",
													"& .MuiSwitch-thumb": {
														width: "12px",
														height: "12px",
													},
													"& .MuiSwitch-switchBase": {
														padding: "5px",
														top: "-1px",
														left: "0",
													},
													"& .MuiSwitch-switchBase.Mui-checked": {
														left: "-10px",
													},
													"& .Mui-checked .MuiSwitch-thumb": {
														backgroundColor: "#FFFFFF",
													},
													"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
														backgroundColor: "#3AE374",
														opacity: 1,
													},
												}}
												checked={
													box?.onclick === fetchedData?.registration_status
												}
											/>
										</div>
									)
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistrationCasesPage;
