import React from "react";
import styles from "./LoadingPage.module.css";

function LoadingPage() {
	return (
		<div className={styles.Loading}>
			<span className={styles.loader}></span>
		</div>
	);
}

export default LoadingPage;
