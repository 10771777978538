import React, { useState, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import OtlobhaAcademyComp from "../../components/OtlobhaAcademyComp/OtlobhaAcademyComp";

import AcademyTabs from "../../components/OtlobhaAcademyComp/AcademyButtons";
import AcademyButtons from "../../components/OtlobhaAcademyComp/AcademyButtons";

const OtlobhaAcademy = () => {
	const [selectedTab, setSelectTab] = useState();
	const [editCourseData, setEditCourseData] = useState(null);
	const [newCourseWindow, setNewCourseWindow] = useState(false);
	const [detailsCourseData, setDetailsCourseData] = useState(null);

	// permissions
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | أكاديمية المنصة</title>
			</Helmet>
			<div className={`p-4 relative 2xl:pl-36 md:bg-[#fafafa] bg-[#FFFFFF]`}>
				<div className='flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4'>
					<PageNavigate currentPage={"أكاديمية المنصة"} />

					<AcademyButtons
						selectedTab={selectedTab}
						permissions={permissions}
						setEditCourseData={setEditCourseData}
						setNewCourseWindow={setNewCourseWindow}
						setDetailsCourseData={setDetailsCourseData}
					/>
				</div>

				<div className='mt-6 md:mt-16'>
					<OtlobhaAcademyComp
						setSelectTab={setSelectTab}
						DetailsCourse={(data) => {
							setNewCourseWindow(true);
							setDetailsCourseData(data);
							setEditCourseData(null);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default OtlobhaAcademy;
