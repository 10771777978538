import React, { useState, useContext } from "react";
import styles from "./AddCountry.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { AiFillStar } from "react-icons/ai";
import { GoArrowRight } from "react-icons/go";
import { useAddNewCountryMutation } from "../../../../RTK/apiSlices/countriesApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const formTitleClasses =
	"md:w-[315px] w-full font-normal md:text-[18px] text-[16px] md:mb-0 mb-2";

const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] px-4 outline-0 rounded-lg";
const formInputStyle = {
	border: "1px solid #A7A7A7",
};

const AddCountry = () => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			code: "",
			name: "",
			name_en: "",
		},
	});

	const [addNewCountry] = useAddNewCountryMutation();

	const addNewCountryHandel = async (formData) => {
		try {
			const res = await addNewCountry({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/countries");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					name_en: res?.data?.message?.en?.name_en?.[0],
					code: res?.data?.message?.en?.code?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.name_en?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.code?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddCountry = (dataCountry) => {
		resetData();
		setLoadingTitle("جاري حفظ الدولة");
		const formData = new FormData();
		formData.append("code", dataCountry?.code);
		formData.append("name", dataCountry?.name);
		formData.append("name_en", dataCountry?.name_en);
		addNewCountryHandel(formData);
	};

	const [DataError, setDataError] = useState({
		name: "",
		name_en: "",
		code: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			name_en: "",
			code: "",
		});
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | اضافة دولة</title>
			</Helmet>
			<BackDrop onClick={() => navigate("/countries")}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-[#F6F6F6] z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddCountry)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='p-4 pt-24 md:p-8 md:pt-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-bold md:text-2xl text-[20px] mb-3'>
								اضافة دولة
							</h2>
							<div className='flex'>
								<div className={`flex items-center gap-2 `}>
									<div
										onClick={() => navigate("/countries")}
										className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
										<GoArrowRight
											style={{ color: "#02466A", fontSize: "1.2rem" }}
										/>
									</div>

									<h2 className='font-normal md:text-lg text-[16px] md:ml-4 ml-2'>
										{" "}
										الإعدادات{" "}
									</h2>
								</div>

								<h2 className='font-normal md:text-lg text-[16px] md:ml-4 ml-2'>
									{" "}
									/ جدول الدول{" "}
								</h2>
								<h3
									className='font-normal md:text-lg text-[16px]'
									style={{ color: "#67747B" }}>
									/ اضافة دولة
								</h3>
							</div>
						</div>
						<div
							className={`flex-1 overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<h2 className={formTitleClasses}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									رقم الدولة<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full md:w-auto'>
									<Controller
										name={"code"}
										control={control}
										rules={{
											required: "حقل الكود مطلوب",
											pattern: {
												value: /^[0-9]+$/i,
												message: "يجب ان يكون الكود أرقام",
											},
										}}
										render={({ field: { onChange, value } }) => (
											<input
												className={formInputClasses}
												style={formInputStyle}
												placeholder='أدخل أرقام فقط'
												type='text'
												name='code'
												value={value}
												onChange={(e) => {
													onChange(e.target.value.replace(/[^0-9]/g, ""));
												}}
											/>
										)}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.code}
										{errors?.code && errors.code.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<h2 className={formTitleClasses}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									اسم الدولة (AR)<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full md:w-auto'>
									<input
										className={formInputClasses}
										style={formInputStyle}
										placeholder='ادخل حروف عربي فقط'
										type='text'
										name='name'
										{...register("name", {
											required: "حقل الاسم مطلوب",
											pattern: {
												value: /^[^-\s][\u0600-\u06FF ]+$/i,
												message: "يجب ان تكون الاحرف بالعربي",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name}
										{errors?.name && errors.name.message}
									</span>
								</div>
							</div>

							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<h2 className={formTitleClasses}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									اسم الدولة (EN)<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full md:w-auto'>
									<input
										className={formInputClasses}
										style={formInputStyle}
										placeholder='ادخل حروف انجليزية فقط'
										type='text'
										name='name_en'
										{...register("name_en", {
											required: "حقل الاسم بالانجليزي مطلوب",
											pattern: {
												value: /^[^-\s][A-Za-z ]+$/i,
												message: "يجب ان تكون الاحرف بالانجليزي",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name_en}
										{errors?.name_en && errors.name_en.message}
									</span>
								</div>
							</div>
						</div>
						<div
							className='flex items-center justify-center gap-4 p-4 md:p-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								btnType={"submit"}
								className={
									"md:h-14 h-[45px]  md:w-1/3 w-11/12  md:text-xl md:text-[18px]"
								}
								style={{ backgroundColor: `rgba(2, 70, 106, 1)` }}
								type={"normal"}>
								حفظ واعتماد
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddCountry;
