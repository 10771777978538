import React, { useEffect, useContext } from "react";
import styles from "./ComplaintDetails.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Context */
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import { TextEditor } from "../../TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import {
	Store,
	Category,
	Call,
	SupportIcon,
	DataIcon,
	StatusIcon,
	TypeSupportIcon,
} from "../../../assets/Icons";
import { useGetTechnicalSupportDataQuery } from "../../../RTK/apiSlices/technicalSupportApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const stateChanges = [
	{
		value: "منتهية",
		BgColor: "#3AE374",
		color: "#ffffff",
	},
	{
		value: "غير منتهية",
		BgColor: "#D3D3D3",
		color: "#ffffff",
	},
	{
		value: "قيد المعالجة",
		BgColor: "#FFFAF3",
		color: "#FF9F1A",
	},
];

const ComplaintDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } =
		useGetTechnicalSupportDataQuery({ technicalSupportId: id });

	const findStateChanges = stateChanges.find(
		(i) => fetchedData?.technicalSupports?.supportstatus === i.value
	);

	const editorContent = useContext(TextEditorContext);
	const { setEditorValue } = editorContent;

	useEffect(() => {
		setEditorValue(fetchedData?.technicalSupports?.content || "");
	}, [fetchedData?.technicalSupports?.content]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | تفاصيل الشكوى</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/technical_support");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className=' md:px-[70px] px-4 md:py-4 pt-24 pb-3'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2
							style={{ color: "#011723" }}
							className='md:text-[24px] text-[20px] font-bold mb-3'>
							تفاصيل الشكوى
						</h2>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={() => {
										navigate("/technical_support");
									}}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#02466A", fontSize: "1.2rem" }}
									/>
								</div>

								<h2
									className='md:text-[18px] text-[16px] ml-4'
									style={{ color: "#011723", whiteSpace: "nowrap" }}>
									{" "}
									الدعم الفني{" "}
								</h2>
							</div>

							<h2
								className='md:text-[18px] text-[16px] ml-4'
								style={{ color: "#011723", whiteSpace: "nowrap" }}>
								{" "}
								/ جدول الشكأوى{" "}
							</h2>

							<h3
								className='md:text-[18px] text-[16px]'
								style={{ color: "#7C7C7C", whiteSpace: "nowrap" }}>
								/ تفاصيل الشكوى
							</h3>
						</div>
					</div>
					<div
						className={`flex-1 flex flex-col md:items-start items-center overflow-y-scroll lg:pt-[30px] lg:pb-[10px] lg:pr-[70px] lg:pl-[155px] p-4 ${styles.content}`}
						style={{ backgroundColor: "#F6F6F6" }}>
						{loading ? (
							<div className='flex flex-col items-center w-full'>
								<CircularLoading />
							</div>
						) : (
							<div className='w-full'>
								<h2
									style={{
										fontSize: "16px",
										color: "#4D4F5C",
										whiteSpace: "nowrap",
									}}>
									رقم الشكوى
								</h2>
								<div
									className='mt-[10px] flex items-center rounded-lg justify-center  md:min-h-[60px] min-h-[45px] w-[180px]'
									style={{ backgroundColor: "#237EAE" }}>
									<h2
										style={{ color: "#EFF9FF" }}
										className='md:text-[24px] text-[20px] font-bold'>
										{fetchedData?.technicalSupports?.id}
									</h2>
								</div>
								<div
									className={
										"lg:w-[752px] w-full mt-[20px] lg:gap-12 gap-4 p-[20px] pr-[22px] flex md:flex-row flex-col justify-between rounded-lg"
									}
									style={{
										maxWidth: "100%",
										backgroundColor: "#fff",
										boxShadow: "0px 3px 6px #0000000F",
									}}>
									<div className='flex flex-col flex-1 gap-5'>
										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<Store className={styles.icons}></Store>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													اسم المتجر
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
													height: "70px",
													width: "180px",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.technicalSupports?.store?.store_name}
												</h2>
											</div>
										</div>
										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<Category className={styles.icons}></Category>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													الأنشطة
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-start rounded-lg gap-4 px-4"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<div className='max-w-[30px]'>
													{fetchedData?.technicalSupports?.store?.activity?.[0]
														?.icon && (
														<img
															src={
																fetchedData?.technicalSupports?.store
																	?.activity[0]?.icon
															}
															alt={
																fetchedData?.technicalSupports?.store
																	?.activity[0]?.name
															}
															loading='lazy'
														/>
													)}
												</div>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{
														fetchedData?.technicalSupports?.store?.activity[0]
															?.name
													}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<Call className={styles.icons}></Call>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													الهاتف
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2
													style={{
														fontSize: "18px",
														color: "#0077FF",
														direction: "ltr",
													}}>
													{fetchedData?.technicalSupports?.store?.phonenumber}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<StatusIcon className={styles.icons}></StatusIcon>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													الحالة
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: findStateChanges?.BgColor,
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2
													style={{
														fontSize: "18px",
														color: findStateChanges?.color,
													}}>
													{findStateChanges?.value}
												</h2>
											</div>
										</div>
									</div>
									<div className='flex flex-col flex-1 gap-5'>
										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<DataIcon className={styles.icons}></DataIcon>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													تاريخ الشكوى
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{moment(
														fetchedData?.technicalSupports?.created_at
													).format("DD/MM/YYYY")}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<TypeSupportIcon
													className={styles.icons}></TypeSupportIcon>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													نوع الاتصال
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#FF00000A",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.technicalSupports?.type}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ width: "136px" }}>
												<SupportIcon className={styles.icons}></SupportIcon>
												<h2
													style={{
														fontSize: "18px",
														color: "#011723",
														whiteSpace: "nowrap",
													}}>
													عنوان الشكوى
												</h2>
											</div>
											<div
												className={
													"md:w-[180px] w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.technicalSupports?.title}
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div className='mt-10 flex flex-col gap-[10px] max-w-full'>
									<h2 style={{ fontSize: "18px", color: "#011723" }}>
										محتوى الشكوى
									</h2>
									<div className={`${styles.editor} lg:w-[752px] w-full`}>
										<TextEditor
											ToolBar={"Details"}
											placeholder={"تفاصيل الرسالة"}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className='p-4 flex justify-center items-center gap-4  md:bg-[#ebebeb] bg-[#F6F6F6]'>
						<Button
							className={
								"md:h-[55px] h-[45px] md:w-1/3 w-11/12 rounded-lg px-8 py-3"
							}
							style={{
								backgroundColor: "#02466A00",
								border: "1px solid #02466A",
							}}
							type={"outline"}
							onClick={() => {
								navigate("/technical_support");
							}}>
							<h2
								className='md:text-[24px] text-[20px]'
								style={{ color: "#02466A" }}>
								إغلاق
							</h2>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComplaintDetails;
