import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import TableComp from "../../../components/SettingComp/CurrencyPageComp/TableComp/TableComp";
import Button from "../../../UI/Button/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { useGetCurrencyQuery } from "../../../RTK/apiSlices/currencyApi";

const CurrencyPage = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetCurrencyQuery({
		page,
		number: rowsPerPage,
	});

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | العملات</title>
			</Helmet>
			<div
				className={`xl:px-4 2xl:pl-36 xl:pb-20 p-4`}
				style={{ backgroundColor: "#F7F7F7" }}>
				<div className='flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4'>
					<PageNavigate
						nestedPage={true}
						parentPage={"الاعدادات"}
						currentPage={"العملات"}
					/>
					{permissions?.includes("admin.currency.store") && (
						<Button
							className='md:h-14 h-[45px] md:w-[213px] w-full md:text-[22px] text-[18px]'
							svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
							type={"normal"}
							style={{ backgroundColor: "#B6BE34" }}
							fontSize={"text-xl font-light"}
							onClick={() => {
								navigate("/currencies/add-currency");
							}}>
							اضافة عملة
						</Button>
					)}
				</div>
				<div className='mt-6 md:mt-16' dir='ltr'>
					<TableComp
						fetchedData={fetchedData}
						loading={loading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
					/>
				</div>
			</div>
		</>
	);
};

export default CurrencyPage;
