import React, { useEffect, useState } from "react";
import styles from "./SearchInput.module.css";
/* Components */
import Suggestions from "./Suggestions";
/* Icons */
import { GiPlainCircle } from "react-icons/gi";
import QuizIcon from "@mui/icons-material/Quiz";
import {
	Dashboard,
	SearchIcon,
	Store,
	Category,
	Graduation,
	Template,
	Pages,
	User,
	Order,
	Support,
	Price,
	DolarIcon,
	CityIcon,
	Email,
	Shipping,
	PaymentGateway,
	ReviewsIcon,
} from "../../assets/Icons/index";

const suggestionsData = [
	{
		id: 1,
		name: "الرئيسية",
		link: "",
		icon: <Dashboard style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 2,
		name: "سوق المنصة",
		link: "souq_atlbha",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 12234,
		name: "طلبات سوق المنصة",
		link: "talabat_souq_atlbha",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 3,
		name: "السوق العام",
		link: "general_souq",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 4,
		name: "المخزون",
		link: "storage",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 5,
		name: "قسم المتاجر",
		link: "markets_section",
		icon: <Store style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 6,
		name: "المتاجر",
		link: "markets",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 7,
		name: "المنتجات",
		link: "products",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 8,
		name: "التوثيق",
		link: "verification",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 9,
		name: "الباقات",
		link: "packages",
		icon: <Price style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 19949,
		name: "خطط الباقات",
		link: "packages/package-features",
		icon: <Price style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 10,
		name: "الإشتراكات الحالية",
		link: "subscriptions",
		icon: <DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 11,
		name: "الخدمات",
		link: "services",
		icon: <Category style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 12,
		name: "أكاديمية المنصة",
		link: "atlbha_academy",
		icon: <Graduation style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 13,
		name: "القالب",
		link: "template_page",
		icon: <Template style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 14,
		name: "السلايدر",
		link: "sliders",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 15,
		name: "التقسيم",
		link: "partitions",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 16,
		name: "الصفحات",
		link: "pages",
		icon: <Pages style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 17,
		name: "المستخدمين",
		link: "users",
		icon: <User style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 18,
		name: "كوبونات الخصم",
		link: "coupons",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 19,
		name: "عرض المندوبين",
		link: "marketers",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 20,
		name: "حالة التسجيل",
		link: "registration_marketers_status",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 21,
		name: "الأنشطة",
		link: "categories_activities",
		icon: <Order style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 22,
		name: "الطلبات",
		link: "orders",
		icon: <Order style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 23,
		name: "الدعم الفني",
		link: "categories_activities",
		icon: <Support style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	// {
	// 	id: 24,
	// 	name: "تواصل معنا",
	// 	link: "contact_us",
	// 	icon: <MarkEmailReadIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	// },
	{
		id: 25,
		name: "شركات الشحن",
		link: "shipping_companies",
		icon: <Shipping style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 26,
		name: "بوابات الدفع",
		link: "payment_gates",
		icon: <PaymentGateway style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 27,
		name: "الاعدادات الاساسية",
		link: "basic_settings",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 28,
		name: "الدول",
		link: "countries",
		icon: <CityIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 29,
		name: "المدن",
		link: "cities",
		icon: <CityIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 30,
		name: "العملات",
		link: "currencies",
		icon: <DolarIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 31,
		name: "البريد",
		link: "emails",
		icon: <Email style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	// {
	// 	id: 32,
	// 	name: "التقرير",
	// 	link: "التقرير",
	//	icon:<ReportIcon style={{ height: "1.2rem", zIndex: "10" }} />}
	// },
	{
		id: 33,
		name: "التواصل الاجتماعي",
		link: "social_media",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 34,
		name: "حالات التسجيل",
		link: "registrations",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 35,
		name: "الاشعارات",
		link: "notifications",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 36,
		name: "إعدادات SEO",
		link: "seo_setting",
		icon: (
			<GiPlainCircle style={{ height: "1rem", width: "1rem", zIndex: "10" }} />
		),
	},
	{
		id: 37,
		name: "تقييمات المنصة",
		link: "reviews",
		icon: <ReviewsIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 38,
		name: "الأسئلة الشائعة",
		link: "faqs",
		icon: <QuizIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
	{
		id: 39,
		name: "اضافة سؤال",
		link: "faqs/add-question",
		icon: <QuizIcon style={{ height: "1.2rem", zIndex: "10" }} />,
	},
];

const SearchInput = () => {
	const [search, setSearch] = useState("");
	const [suggestions, setSuggestions] = useState(null);

	useEffect(() => {
		if (search !== "") {
			setSuggestions(
				suggestionsData?.filter((suggestion) =>
					suggestion?.name?.includes(search)
				)
			);
		} else {
			setSuggestions(null);
		}
	}, [search]);

	return (
		<>
			<label className={`w-full h-full relative ${styles.search_input}`}>
				<input
					placeholder=' هنا ستجد ما تبحث عنه'
					type='text'
					name='name'
					style={{ backgroundColor: "rgba(29, 187, 190, 0.2)" }}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					autoComplete='off'
				/>
			</label>
			<div className={`absolute top-0 right-0 ${styles.search_container}`}>
				<SearchIcon />
			</div>
			{suggestions !== null && (
				<Suggestions
					data={suggestions}
					setSuggestions={setSuggestions}
					setSearch={setSearch}
				/>
			)}
		</>
	);
};
export default SearchInput;
