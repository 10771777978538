import React, { useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import CurrentSubscribersTable from "../../../components/PackagesPageComp/CurrentSubscribersTable/CurrentSubscribersTable";
import TraderAlert from "../../../components/PackagesPageComp/TraderAlert/TraderAlert";
import { useGetSubscriptionsQuery } from "../../../RTK/apiSlices/subscriptionsApi";
import UpdateSubscriptionsDate from "../../../components/PackagesPageComp/UpdateSubscriptionsDate/UpdateSubscriptionsDate";

const CurrentSubscriptions = () => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetSubscriptionsQuery({
		page,
		number: rowsPerPage,
	});
	const [traderAlert, setTraderAlert] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);

	const [traderPackageDetails, setTraderPackageDetails] = useState([]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | الإشتراكات الحالية</title>
			</Helmet>
			<div
				className='p-4 text-lg font-medium 2xl:pl-36'
				style={{ backgroundColor: "#fafafa" }}>
				<PageNavigate
					route={"packages"}
					currentPage={"الإشتراكات الحالية"}
					parentPage={"الباقات"}
				/>
				{/* to send  alert to trader */}
				{traderAlert && (
					<TraderAlert
						cancel={() => {
							setTraderAlert(false);
						}}
						traderPackageDetails={traderPackageDetails}
					/>
				)}

				{/* to update subscriptions date */}
				{openEditModal && (
					<UpdateSubscriptionsDate
						cancel={() => {
							setOpenEditModal(false);
						}}
						traderPackageDetails={traderPackageDetails}
					/>
				)}

				{/* Current Subscribers Table */}
				<div dir='ltr' className='mt-8 md:mt-28'>
					<CurrentSubscribersTable
						fetchedData={fetchedData}
						loading={loading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
						openTraderAlert={(row) => {
							setTraderAlert(true);
							setTraderPackageDetails(row);
						}}
						openEditModal={(row) => {
							setOpenEditModal(true);
							setTraderPackageDetails(row);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default CurrentSubscriptions;
