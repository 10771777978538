import React, { useState, useContext, Fragment } from "react";
import styles from "./AddNewCurrency.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import ImageUploading from "react-images-uploading";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { GrUploadOption } from "react-icons/gr";
import { AiFillStar } from "react-icons/ai";
import { GoArrowRight } from "react-icons/go";
import { useAddNewCurrencyMutation } from "../../../../RTK/apiSlices/currencyApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const formTitleClasses =
	"md:w-[315px] w-full font-normal md:text-[18px] text-[16px] md:mb-0 mb-2";
//
const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] p-4 outline-0 rounded-md";
const formInputStyle = {
	border: "1px solid #A7A7A7",
	backgroundColor: "#fffff",
};

const AddNewCurrency = () => {
	const token = localStorage.getItem("token");

	const navigate = useNavigate();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	// to upload new icon
	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			name_en: "",
		},
	});

	const [addNewCurrency] = useAddNewCurrencyMutation();

	const addNewCurrencyHandel = async (formData) => {
		try {
			const res = await addNewCurrency({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/currencies");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					name_en: res?.data?.message?.en?.name_en?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.name_en?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddCurrency = (dataCurrency) => {
		setLoadingTitle("جاري حفظ العملة");
		resetData();
		let formData = new FormData();
		formData.append("name", dataCurrency?.name);
		formData.append("name_en", dataCurrency?.name_en);
		formData.append("image", images[0]?.file || "");

		addNewCurrencyHandel(formData);
	};

	const [DataError, setDataError] = useState({
		name: "",
		name_en: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			name_en: "",
			image: "",
		});
	};

	return (
		<Fragment>
			<Helmet>
				<title>لوحة تحكم المنصة | اضافة عملة</title>
			</Helmet>
			<BackDrop onClick={() => navigate("/currencies")}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-[#F6F6F6] z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddCurrency)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='p-4 pt-24 md:p-8 md:pt-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-bold md:text-2xl text-[20px] mb-3'>
								اضافة دولة
							</h2>
							<div className='flex'>
								<div className={`flex items-center gap-2 `}>
									<div
										onClick={() => navigate("/currencies")}
										className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
										<GoArrowRight
											style={{ color: "#02466A", fontSize: "1.2rem" }}
										/>
									</div>

									<h2 className='font-normal md:text-lg text-[16px] md:ml-4 ml-2'>
										{" "}
										الإعدادات{" "}
									</h2>
								</div>

								<h2 className='font-normal md:text-lg text-[16px] md:ml-4 ml-2'>
									{" "}
									/ جدول العملات{" "}
								</h2>
								<h3
									className='font-normal md:text-lg text-[16px]'
									style={{ color: "#67747B" }}>
									/ اضافة عملة
								</h3>
							</div>
						</div>
						<div
							className={`flex-1 overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<div className='flex flex-col'>
									<h2 className={formTitleClasses}>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										رمز العملة<span className='text-red-500'>*</span>
									</h2>
									<span className='text-gray-400 text-[14px] font-semibold'>
										(الحد الأقصى للصورة 1MB)
									</span>
								</div>
								<div className='flex flex-col w-full md:w-auto'>
									<ImageUploading
										value={images}
										onChange={onChange}
										maxNumber={2}
										dataURLKey='data_url'
										acceptType={["jpg", "png", "jpeg", "svg"]}>
										{({ onImageUpload, dragProps }) => (
											// write your building UI
											<div
												className='md:w-[555px] w-full md:h-[56px] h-[45px] upload__image-wrapper relative overflow-hidden'
												style={{
													border: "1px solid #A7A7A7",
													borderRadius: "8px",
													backgroundColor: "#fff",
												}}
												onClick={() => {
													onImageUpload();
												}}
												{...dragProps}>
												<div className='w-full h-full cursor-pointer image-item'>
													{!images[0] && (
														<div className='flex flex-col items-center justify-center w-full h-full gap-6'>
															<GrUploadOption size={"1.25rem"}></GrUploadOption>
														</div>
													)}
													{images[0] && (
														<div className='flex flex-col items-center justify-center w-full h-full gap-6'>
															<img
																src={images[0]?.data_url}
																alt=''
																className='object-cover w-8 h-8'
																loading='lazy'
															/>
														</div>
													)}
												</div>
											</div>
										)}
									</ImageUploading>
									<span className='text-red-500 text-[16px]'>
										{DataError?.image}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<h2 className={formTitleClasses}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									اسم العملة (AR)<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full md:w-auto'>
									<input
										className={formInputClasses}
										style={formInputStyle}
										placeholder='ادخل حروف عربي فقط'
										type='text'
										name='name'
										{...register("name", {
											required: "حقل الاسم مطلوب",
											pattern: {
												value: /^[^-\s][\u0600-\u06FF ]+$/i,
												message: "يجب ان تكون الاحرف بالعربي",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name}
										{errors?.name && errors.name.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
								<h2 className={formTitleClasses}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									اسم العملة (EN)<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full md:w-auto'>
									<input
										className={formInputClasses}
										style={formInputStyle}
										placeholder='ادخل حروف انجليزية فقط'
										type='text'
										name='name_en'
										{...register("name_en", {
											required: "حقل الاسم بالانجليزي مطلوب",
											pattern: {
												value: /^[^-\s][A-Za-z ]+$/i,
												message: "يجب ان تكون الاحرف بالانجليزي",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name_en}
										{errors?.name_en && errors.name_en.message}
									</span>
								</div>
							</div>
						</div>
						<div
							className='flex items-center justify-center gap-4 p-4 md:p-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								className={
									"md:h-14 h-[45px]  md:w-1/3 w-11/12 md:text-xl md:text-[18px]"
								}
								style={{ backgroundColor: `rgba(2, 70, 106, 1)` }}
								type={"normal"}>
								حفظ واعتماد
							</Button>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default AddNewCurrency;
