import React, { useState, useContext } from "react";
import styles from "./CreateRole.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { AiFillStar } from "react-icons/ai";
import {
	useAddNewRoleMutation,
	useGetPermissionsQuery,
} from "../../../RTK/apiSlices/rolesApi";

const CreateRole = () => {
	const navigate = useNavigate();
	const { data: fetchedData, isLoading: loading } = useGetPermissionsQuery();

	const [roleName, setRoleName] = useState("");
	const [permissions, setPermissions] = useState([]);
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [DataError, setDataError] = useState({
		role_name: "",
		permissions: "",
	});
	const resetData = () => {
		setDataError({
			role_name: "",
			permissions: "",
		});
	};
	const [addNewRole] = useAddNewRoleMutation();
	const addNewRoleHandel = async (formData) => {
		try {
			const res = await addNewRole({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/users/roles");
			} else {
				setLoadingTitle(null);

				setDataError({
					role_name: res?.data?.message?.en?.role_name?.[0],
					permissions: res?.data?.message?.en?.permissions?.[0],
				});
				toast.error(res?.data?.message?.en?.role_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.permissions?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const createRole = () => {
		resetData();
		setLoadingTitle("جاري حفظ الدور");
		const data = {
			role_name: roleName,
			permissions: permissions,
		};
		addNewRoleHandel(data);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | اضافة دور</title>
			</Helmet>
			<div
				className={`fixed md:top-20 top-0 left-0 bg-slate-50 z-50 w-full full_screen_page flex h-screen md:pb-20 overflow-y-scroll flex-col justify-between `}>
				<div
					className='flex flex-col items-start justify-end px-4 py-8 lg:pb-4 lg:pr-14'
					style={{
						height: "100px",
						backgroundColor: "rgba(235, 235, 235, 1)",
					}}>
					<h2 className='font-bold md:text-2xl text-[20px] mb-3'>
						انشاء دور جديد
					</h2>
					<div className='flex items-center'>
						<div
							onClick={() => navigate("/users/roles")}
							className={`flex cursor-pointer items-center gap-2 `}>
							<div
								className={`gap-2 bg-[#02466a17] flex justify-center items-center h-6 w-6 rounded-full ${styles.arrow_con}`}>
								<GoArrowRight
									style={{ color: "#02466A", fontSize: "1.2rem" }}
								/>
							</div>

							<h2 className='font-medium md:text-[18px] text-[16px] ml-4'>
								{" "}
								الأدوار الوظيفية{" "}
							</h2>
						</div>

						<h3
							className='font-medium md:text-[18px] text-[16px]'
							style={{ color: "#7C7C7C" }}>
							/ انشاء دور جديد
						</h3>
					</div>
				</div>
				<div
					className={`flex-1 overflow-y-scroll lg:pl-5  lg:pr-12 py-4 ${styles.content}`}
					style={{ backgroundColor: "#F6F6F6" }}>
					<div className='mb-3 md:my-2'>
						<h2
							className='font-normal md:text-[18px] text-[16px] mb-2'
							style={{ color: "#011723" }}>
							<AiFillStar
								style={{
									display: "inline-block",
									marginLeft: "0.5rem",
									color: "red",
								}}></AiFillStar>
							اسم الدور الوظيفي<span className='text-red-500'>*</span>
						</h2>
						<input
							className='md:w-[376px] w-full outline-none p-4 rounded-lg h-14'
							style={{
								border: "1px solid #A7A7A7",
								backgroundColor: "#FFFFFF",
								width: "376px",
								color: "#0099FB",
							}}
							type='text'
							placeholder='ادخل اسم الدور بالحروف فقط'
							value={roleName}
							onChange={(e) => setRoleName(e.target.value)}
						/>
						<br />
						<span className='text-red-500 text-[16px]'>
							{DataError?.role_name}
						</span>
					</div>
					<div className='md:mt-3 '>
						<h2
							className='font-normal md:text-[18px] text-[16px] whitespace-nowrap mb-2'
							style={{ color: "#011723" }}>
							<AiFillStar
								style={{
									display: "inline-block",
									marginLeft: "0.5rem",
									color: "red",
								}}></AiFillStar>
							حدد الصلاحيات<span className='text-red-500'>*</span>
						</h2>
						<br />
						<span className='text-red-500 text-[16px]'>
							{DataError?.permissions}
						</span>
					</div>
					<div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full h-full'>
								<CircularLoading />
							</div>
						) : (
							fetchedData?.permissions?.map((row, index) => (
								<TableContainer
									key={index}
									component={Paper}
									sx={{ p: 0, w: 100, marginBottom: "24px" }}>
									<Table>
										<TableHead
											sx={{
												bgcolor: "#eff9ff",
												fontSize: "1.2rem",
											}}>
											<TableRow>
												<TableCell
													sx={{
														fontSize: "1.2rem",
													}}
													align='right'>
													اسم الصلاحية
												</TableCell>
												{row?.subpermissions?.map((sub, index) => (
													<TableCell
														key={index}
														TableCell
														align='center'
														sx={{ fontSize: "1rem", textAlign: "right" }}>
														{sub?.name_ar}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{loading ? (
												<TableRow>
													<TableCell colSpan={6}>
														<CircularLoading />
													</TableCell>
												</TableRow>
											) : (
												<TableRow key={index}>
													<TableCell
														sx={{
															border: "none",

															fontSize: "1.2rem",
															padding: "10px",
														}}
														align='right'>
														{row?.name_ar}
													</TableCell>
													{row?.subpermissions?.map((sub, index) => (
														<TableCell
															key={index}
															sx={{ border: "none" }}
															align='center'>
															<FormGroup
																className=''
																sx={{ overflow: "hidden" }}>
																<FormControlLabel
																	value={sub?.id}
																	key={index}
																	sx={{
																		py: 1,
																		mr: 0,

																		"& .MuiTypography-root": {
																			fontSize: "20px",
																			fontWeight: "500",
																			"@media(max-width:767px)": {
																				fontSize: "16px",
																			},
																		},
																	}}
																	control={
																		<Checkbox
																			onChange={(e) => {
																				if (e.target.checked) {
																					setPermissions([
																						...permissions,
																						parseInt(e.target.value),
																					]);
																				} else {
																					setPermissions(
																						permissions?.filter(
																							(item) => item !== sub?.id
																						)
																					);
																				}
																			}}
																			sx={{ "& path": { fill: "#000000" } }}
																		/>
																	}
																/>
															</FormGroup>
														</TableCell>
													))}
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							))
						)}
					</div>
				</div>
				<div
					className='md:p-8 p-4 flex justify-center items-center gap-4 md:h-[105px] h-[100px]'
					style={{
						backgroundColor: "rgba(235, 235, 235, 1)",
					}}>
					<Button
						className={"md:h-14 h-[45px] w-[189px] md:text-2xl text-[18px] "}
						style={{ borderColor: `rgba(2, 70, 106, 1)` }}
						type={"outline"}
						textStyle={{ color: "#02466A" }}
						onClick={() => {
							createRole();
						}}>
						حفظ واعتماد
					</Button>
				</div>
			</div>
		</>
	);
};

export default CreateRole;
