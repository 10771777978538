import React, { useState, useContext } from "react";
import styles from "./TraderAlert.module.css";

/* Third party */
import { useForm } from "react-hook-form";

/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
import { TextEditorContext } from "../../../../store/TextEditorProvider";
/* Components */
import { TextEditor } from "../../../TextEditor";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { FiSend } from "react-icons/fi";
import { useAddStoreNoteMutation } from "../../../../RTK/apiSlices/verificationApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const TraderAlert = ({ cancel, traderPackageDetails }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			subject: "",
		},
	});

	const [DataError, setDataError] = useState({
		subject: "",
		details: "",
	});
	const resetData = () => {
		setDataError({
			subject: "",
			details: "",
		});
	};

	const [addStoreNote] = useAddStoreNoteMutation();

	const addStoreNoteHandel = async (data) => {
		try {
			const res = await addStoreNote(data);
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				cancel();
				setEditorValue("");
			} else {
				setLoadingTitle(null);
				setDataError({
					subject: res?.data?.message?.en?.subject?.[0],
					details: res?.data?.message?.en?.details?.[0],
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleTraderAlert = (dataTraderAlert) => {
		setLoadingTitle("جاري ارسال الملاحظة");
		resetData();
		const data = {
			store_id: traderPackageDetails?.id,
			subject: dataTraderAlert?.subject,
			details: editorValue,
		};
		addStoreNoteHandel(data);
	};

	return (
		<>
			<BackDrop onClick={cancel} />
			<div
				className='fixed trader_alert   flex flex-col md:top-1/2 top-[55%] translate-x-2/4 -translate-y-2/4 right-2/4 z-20 rounded-lg overflow-hidden'
				style={{ width: "51.25rem", maxWidth: "90%", maxHeight: "662px" }}>
				<form onSubmit={handleSubmit(handleTraderAlert)} className='h-full'>
					<div
						className='flex items-center justify-center w-full h-16 px-4 py-4 trader_alert'
						style={{ backgroundColor: "#1DBBBE" }}>
						<h2
							style={{ color: "#ECFEFF" }}
							className='md:text-[22px] text-[18px] font-medium text-center'>
							ارسال ملاحظة
						</h2>
					</div>
					<div className='flex-1 pb-4' style={{ backgroundColor: "#FAFAFA" }}>
						<div
							style={{
								backgroundColor: "#F4F5F7",
								border: "1px solid #67747B33",
							}}
							className='flex flex-row items-center gap-2 px-5 py-4'>
							<h2
								style={{ color: "#011723" }}
								className='md:text-[20px] text-[16px] font-medium'>
								إلى :
							</h2>
							<span
								style={{ color: "#67747B" }}
								className='md:text-[20px] text-[16px] font-medium'>
								{traderPackageDetails?.store_email}
							</span>
						</div>
						<textarea
							style={{ color: "#67747B" }}
							className='md:text-[18px] text-[16px] w-full px-4 pt-2 text-md font-medium outline-none resize-none'
							placeholder='الموضوع'
							rows={2}
							name='subject'
							{...register("subject", {
								required: "حقل الموضوع مطلوب",
								pattern: {
									value: /^[^-\s][A-Za-zأ-ي0-9_/@. ]+$/i,
									message: "يحب ان يكون الموضوع نصا",
								},
							})}></textarea>
						<div className='px-3'>
							<span className='text-red-500 text-[16px]'>
								{DataError?.subject}
								{errors?.subject && errors.subject.message}
							</span>
						</div>
						<div
							style={{
								backgroundColor: "#F4F5F7",
								border: "1px solid #67747B33",
							}}
							className='flex flex-row items-center gap-4 px-5 py-4'>
							<h2 className='font-medium text-md'>نص الرسالة</h2>
						</div>
						<div className={styles.editor}>
							<TextEditor
								ToolBar={"TraderAlert"}
								placeholder={"الرسالة النصية"}
							/>
						</div>
						<div className='px-3 py-1'>
							<span className='text-red-500 text-[16px]'>
								{DataError?.details}
							</span>
						</div>
						<div className='flex justify-center gap-5'>
							<Button
								btnType={"submit"}
								type={"normal"}
								className='md:text-[20px] text-[16px] text-center mt-12'
								style={{ backgroundColor: "#02466A" }}
								svg={<FiSend color={"#fff"} />}>
								ارسال
							</Button>
							<Button
								type={"outline"}
								className='md:text-[20px] text-[16px] text-center  mt-12'
								style={{ borderColor: "#02466A" }}
								textStyle={{ color: "#02466A" }}
								onClick={cancel}>
								الغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default TraderAlert;
