import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Context from "../../store/context";
import { LoadingContext } from "../../store/LoadingProvider";
import CircularLoading from "../../UI/CircularLoading/CircularLoading";
import Switch from "@mui/material/Switch";
import {
	useChangePaymentTypeStatusMutation,
	useGetPaymentTypeQuery,
} from "../../RTK/apiSlices/paymentTypeApi";

const PaymentGatewaysPage = () => {
	const { data: fetchedData, isLoading: loading } = useGetPaymentTypeQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [changePaymentTypeStatus] = useChangePaymentTypeStatusMutation();
	const changePageStatus = async (id) => {
		setLoadingTitle("جاري تعديل بوابة الدفع");
		try {
			await changePaymentTypeStatus({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setLoadingTitle(null);
						setEndActionTitle(data?.message?.ar);
					} else {
						setLoadingTitle(null);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | بوابات الدفع</title>
			</Helmet>
			<div
				className='relative h-full p-4 xl:pb-12 xl:pr-8'
				style={{ backgroundColor: "#F7F7F7" }}>
				<h3
					style={{ color: "#011723" }}
					className='md:text-[24px] text-[20px] font-bold'>
					بوابات الدفع
				</h3>

				{loading ? (
					<div className='flex flex-col items-center justify-center w-full h-full '>
						<CircularLoading />
					</div>
				) : (
					<div className='flex flex-row flex-wrap items-center mt-8 gap-x-4 gap-y-10'>
						{fetchedData?.paymenttypes?.map((payment) => (
							<div
								key={payment?.id}
								style={{ boxShadow: "0 0 20px 0px #ededed" }}
								className='flex flex-col h-[200px] md:w-[230px] w-full bg-white'>
								<div className='flex flex-col items-center justify-center flex-1 gap-2 p-4'>
									<div className='w-full h-[50px] flex justify-center items-center'>
										<img
											src={payment?.image}
											alt=''
											className='h-full max-w-full '
											loading='lazy'
										/>
									</div>
								</div>
								<div className='min-h-[50px] bg-[#f7f8f8] flex flex-col items-center justify-center'>
									<Switch
										title='تغيير الحالة'
										onChange={() => changePageStatus(payment?.id)}
										sx={{
											width: "32px",
											height: "20px",
											padding: 0,
											borderRadius: "12px",
											"& .MuiSwitch-thumb": {
												width: "12px",
												height: "12px",
											},
											"& .MuiSwitch-switchBase": {
												padding: "5px",
												top: "-1px",
												left: "0",
											},
											"& .MuiSwitch-switchBase.Mui-checked": {
												left: "-10px",
											},
											"& .Mui-checked .MuiSwitch-thumb": {
												backgroundColor: "#FFFFFF",
											},
											"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
												backgroundColor: "#3AE374",
												opacity: 1,
											},
										}}
										checked={payment?.status === "نشط" ? true : false}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default PaymentGatewaysPage;
