import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Context from "../../store/context";
import { LoadingContext } from "../../store/LoadingProvider";
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
import CircularLoading from "../../UI/CircularLoading/CircularLoading";
import Switch from "@mui/material/Switch";
import {
	useChangeShippingTypeStatusMutation,
	useGetShippingTypeQuery,
} from "../../RTK/apiSlices/shippingTypeApi";

const ShippingCompaniesPage = () => {
	const { data: fetchedData, isLoading: loading } = useGetShippingTypeQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [changeShippingTypeStatus] = useChangeShippingTypeStatusMutation();
	const changePageStatus = async (id) => {
		setLoadingTitle("جاري تعديل شركة الشحن");
		try {
			await changeShippingTypeStatus({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setLoadingTitle(null);
						setEndActionTitle(data?.message?.ar);
					} else {
						setLoadingTitle(null);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | شركات الشحن</title>
			</Helmet>
			<div
				className='relative h-full xl:pb-[52px]  xl:pr-8 p-4'
				style={{ backgroundColor: "#F7F7F7" }}>
				<h3
					style={{ color: "#011723" }}
					className='md:text-[24px] text-[20px] font-bold'>
					شركات الشحن
				</h3>

				{loading ? (
					<div className='flex flex-col items-center justify-center w-full h-full '>
						<CircularLoading />
					</div>
				) : (
					<div className='flex flex-col mt-4'>
						<div className='flex flex-col gap-4 mt-6 xs:flex-row'>
							{fetchedData?.shippingtypes?.map((company) => (
								<div
									key={company?.id}
									style={{ boxShadow: "0 0 20px 0px #ededed" }}
									className='flex flex-col h-[200px] md:w-[230px] w-full bg-white'>
									<div className='flex flex-col items-center justify-center flex-1 gap-2 p-4'>
										<div className='w-full h-[80px] flex justify-center items-center'>
											<img
												src={company?.image}
												alt=''
												className='h-full max-w-full '
												loading='lazy'
											/>
										</div>
									</div>
									<div className='min-h-[50px] bg-[#f7f8f8] flex flex-col items-center justify-center'>
										{permissions?.includes(
											"admin.shippingtype.changeStatus"
										) && (
											<Switch
												title='تغيير الحالة'
												onChange={() => changePageStatus(company?.id)}
												sx={{
													width: "32px",
													height: "20px",
													padding: 0,
													borderRadius: "12px",
													"& .MuiSwitch-thumb": {
														width: "12px",
														height: "12px",
													},
													"& .MuiSwitch-switchBase": {
														padding: "5px",
														top: "-1px",
														left: "0",
													},
													"& .MuiSwitch-switchBase.Mui-checked": {
														left: "-10px",
													},
													"& .Mui-checked .MuiSwitch-thumb": {
														backgroundColor: "#FFFFFF",
													},
													"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
														backgroundColor: "#3AE374",
														opacity: 1,
													},
												}}
												checked={company?.status === "نشط" ? true : false}
											/>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ShippingCompaniesPage;
