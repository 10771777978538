import React from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import { useGetSectionsDataQuery } from "../../../RTK/apiSlices/templateApi";
import PartitionsSections from "../../../components/TemplatePageComps/PartitionsPage/PartitionsSections";

const PartitionsPage = () => {
	const { data: fetchedData, isLoading: loading } = useGetSectionsDataQuery();
	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | التقسيم</title>
			</Helmet>
			<div
				className={`px-4 md:pt-4 pt-0 pb-10`}
				style={{ backgroundColor: "#fafafa" }}>
				<div
					className='md:pr-5 py-3 px-2 rounded font-normal md:text-lg text-[14px]'
					style={{ color: "#EFF9FF", backgroundColor: "#237EAE" }}>
					هذه الواجهة خاصة بإعدادات الصفحة الرئيسية للموقع الإلكتروني
				</div>
				<div className='mt-4 md:mt-6'>
					<PageNavigate
						className='text-lg font-normal'
						route={"template_page"}
						parentPage={"القالب"}
						currentPage={"التقسيم"}
					/>
				</div>
				<div className='mt-5 2xl:pl-36 xl:mt-10'>
					<PartitionsSections fetchedData={fetchedData} loading={loading} />
				</div>
			</div>
		</>
	);
};

export default PartitionsPage;
