import React, { useEffect, useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";

/* Components */
import DomainOrdersTable from "../../../components/OrderPageComp/TableComp/DomainOrdersTable";
/* MUI */
import FormControl from "@mui/material/FormControl";
/* Icons */
import { AiOutlineSearch } from "react-icons/ai";
import {
	useGetDomainOrdersQuery,
	useSearchInDomainOrdersMutation,
} from "../../../RTK/apiSlices/domainOrdersApi.js";

const DomainOrders = () => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetDomainOrdersQuery({
		page,
		number: rowsPerPage,
	});

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let resultData = fetchedData?.Websiteorder || [];

	if (search !== "") {
		resultData = searchResults?.orders;
	} else {
		resultData = fetchedData?.Websiteorder;
	}

	const [searchInWebsiteOrders, { isLoading: searchIsLoading }] =
		useSearchInDomainOrdersMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInWebsiteOrders({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | طلبات الدومين</title>
			</Helmet>
			<div
				className='relative p-4 2xl:pl-36 h-fit xl:pr-8'
				style={{ backgroundColor: "#F7F7F7" }}>
				<div className={"mt-8"} style={{ backgroundColor: "#FFF" }}>
					<div className='p-0 md:p-4'>
						<div className='flex flex-col items-start justify-between p-3 mb-4 lg:flex-row lg:items-center gap-y-4 md:p-0'>
							<h2
								className={
									"font-bold md:text-2xl text-[18px] whitespace-nowrap"
								}
								style={{ color: "#011723" }}>
								جدول طلبات الدومين الخاصة بالمتاجر
							</h2>

							{/** search order input */}
							<div style={{ maxWidth: "100%" }}>
								<FormControl
									className='flex flex-row w-full gap-4'
									sx={{ minWidth: 120, maxWidth: "100%", flex: "1" }}>
									<label className={`w-full flex-1 md:h-14 h-[45px] relative `}>
										<input
											className='h-full outline-0 pr-12 rounded-lg bg-[#FFFFFF00]'
											placeholder=' ادخل رقم الطلب'
											type='text'
											name='name'
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											style={{
												width: "376px",
												maxWidth: "100%",
												border: "1px solid #A7A7A7",
											}}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<AiOutlineSearch color='#B6BE34' size={"18px"} />
										</div>
									</label>
								</FormControl>
							</div>
						</div>
						{/** Orders Table  */}
						<div dir={"ltr"}>
							<DomainOrdersTable
								fetchedData={resultData || []}
								loading={loading || searchIsLoading}
								pageNumber={page}
								setPageNumber={setPage}
								currentPage={rowsPerPage}
								setCurrentPage={setRowsPerPage}
								page_count={fetchedData?.page_count}
								search={search}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DomainOrders;
