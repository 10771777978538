import React, { useState } from "react";
import styles from "./MainPage.module.css";
/* Third party */
import { Helmet } from "react-helmet";

/* Components */
import QuickDetails from "../../components/MainPageComp/QuickDetails/QuickDetails";
import StoreGraph from "../../components/MainPageComp/StoreGraph/StoreGraph";
import OrdersGraph from "../../components/MainPageComp/OrdersGraph/OrdersGraph";
import SystemAreaSec from "../../components/MainPageComp/SystemAreaSec/SystemAreaSec";
import ProductsInfo from "../../components/MainPageComp/ProductsInfo/ProductsInfo";

// RTK Query
import { useGetMainPageDataQuery } from "../../RTK/apiSlices/mainPageApi";

const MainPage = () => {
	const [startDate2, setStartDate2] = useState("");
	const [endDate2, setEndDate2] = useState("");
	const [startDate1, setStartDate1] = useState("");
	const [endDate1, setEndDate1] = useState("");

	// Filter data in home page based start and end date
	const { data: mainPage, isLoading } = useGetMainPageDataQuery({
		startDate1,
		endDate1,
		startDate2,
		endDate2,
	});

	const getStartDate2 = (y) => {
		setStartDate2(y);
	};
	const getEndDate2 = (y) => {
		setEndDate2(y);
	};
	const getStartDate1 = (y) => {
		setStartDate1(y);
	};
	const getEndDate1 = (y) => {
		setEndDate1(y);
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم المنصة | الرئيسية</title>
			</Helmet>
			<div className={`${styles.main_page} md:p-4 py-4 px-4`}>
				<QuickDetails
					count_of_stores={mainPage?.count_of_stores}
					average_of_stores={mainPage?.average_of_stores}
					count_of_marketers={mainPage?.count_of_marketers}
					average_of_marketers={mainPage?.average_of_marketers}
					count_of_services={mainPage?.count_of_services}
					average_of_services={mainPage?.average_of_services}
					loading={isLoading}
				/>
				<StoreGraph
					loading={isLoading}
					array_store={mainPage?.array_store}
					count_of_stores={mainPage?.count_of_stores}
					Subscriptions={mainPage?.Subscriptions}
					all_income={mainPage?.all_income}
					Etlobha_income={mainPage?.Etlobha_income}
					getStartDate1={getStartDate1}
					getEndDate1={getEndDate1}
				/>
				<OrdersGraph
					Total_orders={mainPage?.Total_orders}
					count_orders_of_Etlobha={mainPage?.count_orders_of_Etlobha}
					loading={isLoading}
					getStartDate2={getStartDate2}
					getEndDate2={getEndDate2}
				/>
				<SystemAreaSec
					Subscriptions_city={mainPage?.Subscriptions_city}
					loading={isLoading}
				/>
				<ProductsInfo
					more_product_visit={mainPage?.more_product_visit}
					more_store_visit={mainPage?.more_store_visit}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default MainPage;
